import Button from "antd/lib/button";
import Card from "antd/lib/card";
import DatePicker from "antd/lib/date-picker";
import Divider from "antd/lib/divider";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import message from "antd/lib/message";
import PageHeader from "antd/lib/page-header";
import Progress from "antd/lib/progress";
import Space from "antd/lib/space";
import Tag from "antd/lib/tag";
import Typography from "antd/lib/typography";
import isEqual from "lodash/isEqual";
import moment, { Moment } from "moment";
import React, { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import config from "~config";
import LayoutContext from "~context/Layout";
import UserContext from "~context/User";
import sleep from "~utilities/sleep";

type FormProfileValues = {
    firstName: string;
    lastName: string;
    dateOfBirth?: Moment;
    phoneNumber?: string
};

type FormPasswordValues = {
    password: string;
    newPassword: string;
    confirmNewPassword: string;
};

type PasswordStrength =
    | "very weak"
    | "weak"
    | "moderate"
    | "strong"
    | "very strong";

function SettingsPage(): JSX.Element {
    const layout = useContext(LayoutContext);
    const user = useContext(UserContext);

    const [formProfile] = Form.useForm<FormProfileValues>();
    const [formProfileInitialValues, setFormProfileInitialValues] =
        useState<FormProfileValues>();
    const [formProfileValues, setFormProfileValues] = useState<
        FormProfileValues | undefined
    >(formProfileInitialValues);
    const [formProfileSubmitting, setFormProfileSubmitting] =
        useState<boolean>(false);
    const formProfileUnchanged =
        formProfileValues === undefined ||
        isEqual(formProfileInitialValues, formProfileValues);

    const [formPassword] = Form.useForm<FormPasswordValues>();
    const formPasswordInitialValues: FormPasswordValues = {
        password: "",
        newPassword: "",
        confirmNewPassword: "",
    };
    const [formPasswordValues, setFormPasswordValues] =
        useState<FormPasswordValues>(formPasswordInitialValues);
    const [formPasswordSubmitting, setFormPasswordSubmitting] =
        useState<boolean>(false);
    const formPasswordUnchanged = isEqual(
        formPasswordInitialValues,
        formPasswordValues
    );
    const formPasswordNewPasswordStrength = getPasswordStrength(
        formPasswordValues.newPassword
    );

    useEffect(() => {
        if (layout.data && layout.actions) {
            if (layout.data.header !== true) layout.actions.updateHeader(true);
            if (layout.data.footer !== true) layout.actions.updateFooter(true);
        }
    }, [layout.actions, layout.data]);

    useEffect(() => {
        if (user.data != null)
            setFormProfileInitialValues({
                firstName: user.data.firstName,
                lastName: user.data.lastName,
                dateOfBirth: user.data.dateOfBirth
                    ? moment(user.data.dateOfBirth)
                    : undefined,
                phoneNumber: user.data.phoneNumber ?? ''
            });
    }, [user.data]);

    return (
        <>
            <Helmet>
                <title>Settings - Twenty4 Tan Co.</title>
            </Helmet>

            <div className="container-md my-50 px-50">
                <Card
                    className="br-10 bs-grey-5"
                    extra={
                        user.data &&
                        user.data.dateOfBirth &&
                        !isAdult(user.data.dateOfBirth) && (
                            <Tag color="warning">Minor</Tag>
                        )
                    }
                    loading={!user.data}
                    title={<PageHeader className="p-0" title="Settings" />}
                >
                    {formProfileInitialValues && (
                        <>
                            <Typography.Title
                                className="mb-25 font-weight-400"
                                level={5}
                            >
                                Profile
                            </Typography.Title>
                            <Form
                                colon={false}
                                form={formProfile}
                                initialValues={formProfileInitialValues}
                                labelAlign="left"
                                labelCol={{ span: 24, md: 8 }}
                                requiredMark={false}
                                wrapperCol={{ span: 24, md: 16 }}
                                onFinish={async (values) => {
                                    if (
                                        user.data &&
                                        user.set &&
                                        !formProfileSubmitting
                                    ) {
                                        setFormProfileSubmitting(true);
                                        await sleep(1);
                                        try {
                                            const response = await fetch(
                                                `${config.TWENTY4TANCO_API_BASE_URL}/user/profile`,
                                                {
                                                    method: "PUT",
                                                    headers: {
                                                        Accept: "application/json",
                                                        "Content-Type":
                                                            "application/json",
                                                    },
                                                    body: JSON.stringify(
                                                        {
                                                            ...values,
                                                            phoneNumber: values.phoneNumber === '' ? null : values.phoneNumber
                                                        }
                                                    ),
                                                    credentials: "include",
                                                }
                                            );

                                            const result: HttpResult =
                                                await response.json();
                                            if (result.status === "success") {
                                                user.set({
                                                    firstName: values.firstName,
                                                    lastName: values.lastName,
                                                    dateOfBirth:
                                                        values.dateOfBirth
                                                            ? values.dateOfBirth.format(
                                                                  "YYYY-MM-DD"
                                                              )
                                                            : undefined,
                                                    phoneNumber: values.phoneNumber === '' ? null : values.phoneNumber ?? null,
                                                    metadata: {
                                                        createdAt:
                                                            user.data.metadata
                                                                .createdAt,
                                                        updatedAt:
                                                            moment().format(
                                                                "YYYY-MM-DD"
                                                            ),
                                                    },
                                                });
                                                message.success(
                                                    "Profile updated"
                                                );
                                            } else {
                                            }
                                        } catch (e) {
                                            console.error(e);
                                        }
                                        setFormProfileSubmitting(false);
                                    }
                                }}
                                onReset={() =>
                                    setFormProfileValues(
                                        formProfileInitialValues
                                    )
                                }
                                onValuesChange={(_, values) => {
                                    if (!formProfileSubmitting)
                                        setFormProfileValues(values);
                                }}
                            >
                                <Form.Item
                                    className="mb-10"
                                    //hasFeedback={formProfileValues?.firstName !== formProfileInitialValues?.firstName}
                                    label="First name"
                                    name="firstName"
                                    required={true}
                                    rules={[
                                        {
                                            message: "First name is required.",
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        autoComplete="given-name"
                                        className="br-5"
                                        readOnly={formProfileSubmitting}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className="mb-10"
                                    //hasFeedback={formProfileValues?.lastName !== formProfileInitialValues?.lastName}
                                    label="Last name"
                                    name="lastName"
                                    required={true}
                                    rules={[
                                        {
                                            message: "Last name is required.",
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        autoComplete="family-name"
                                        className="br-5"
                                        readOnly={formProfileSubmitting}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className="mb-10"
                                    label="Date of birth"
                                    name="dateOfBirth"
                                >
                                    <DatePicker className="br-5 w-100" />
                                </Form.Item>

                                <Form.Item
                                    className="mb-10"
                                    label="Phone number"
                                    name="phoneNumber"
                                >
                                    <Input
                                        autoComplete="tel"
                                        className="br-5"
                                        readOnly={formProfileSubmitting}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className="mb-0 text-right"
                                    wrapperCol={{ md: { offset: 8 } }}
                                >
                                    <Space>
                                        <Button
                                            className="br-5"
                                            disabled={
                                                formProfileUnchanged ||
                                                formProfileSubmitting
                                            }
                                            htmlType="reset"
                                            type="default"
                                        >
                                            Reset
                                        </Button>
                                        <Button
                                            className="br-5"
                                            disabled={formProfileUnchanged}
                                            htmlType="submit"
                                            loading={formProfileSubmitting}
                                            type="primary"
                                        >
                                            Update
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>

                            <Divider />

                            <Typography.Title
                                className="mb-25 font-weight-400"
                                level={5}
                            >
                                Password
                            </Typography.Title>
                            <Form
                                colon={false}
                                form={formPassword}
                                initialValues={formPasswordInitialValues}
                                labelAlign="left"
                                labelCol={{ span: 24, md: 8 }}
                                requiredMark={false}
                                wrapperCol={{ span: 24, md: 16 }}
                                onFinish={async (values) => {
                                    if (!formPasswordSubmitting) {
                                        setFormPasswordSubmitting(true);
                                        try {
                                            const response = await fetch(
                                                `${config.TWENTY4TANCO_API_BASE_URL}/user/password`,
                                                {
                                                    method: "PUT",
                                                    headers: {
                                                        Accept: "application/json",
                                                        "Content-Type":
                                                            "application/json",
                                                    },
                                                    body: JSON.stringify(
                                                        values
                                                    ),
                                                    credentials: "include",
                                                }
                                            );

                                            const result: HttpResult =
                                                await response.json();
                                            if (result.status === "success") {
                                                message.success(
                                                    "Password updated"
                                                );
                                                formPassword.resetFields();
                                                setFormPasswordValues(
                                                    formPasswordInitialValues
                                                );
                                            } else {
                                                message.error(
                                                    result.message ||
                                                        "Failed to update password."
                                                );
                                            }
                                        } catch (e) {
                                            console.error(e);
                                        }
                                        setFormPasswordSubmitting(false);
                                    }
                                }}
                                onReset={() =>
                                    setFormPasswordValues(
                                        formPasswordInitialValues
                                    )
                                }
                                onValuesChange={(_, values) => {
                                    if (!formProfileSubmitting)
                                        setFormPasswordValues(values);
                                }}
                            >
                                <Form.Item
                                    hidden={true}
                                    label="Email address"
                                    name="emailAddress"
                                >
                                    <Input
                                        autoComplete="email"
                                        className="br-5"
                                        disabled={true}
                                        type="email"
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="mb-10"
                                    label="Current password"
                                    name="password"
                                >
                                    <Input.Password
                                        autoComplete="current-password"
                                        className="br-5"
                                        readOnly={formPasswordSubmitting}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="mb-10"
                                    extra={
                                        formPasswordNewPasswordStrength !==
                                            undefined &&
                                        renderPasswordStrengthBar(
                                            formPasswordNewPasswordStrength
                                        )
                                    }
                                    //hasFeedback={formPasswordValues.newPassword.length > 0}
                                    label="New password"
                                    name="newPassword"
                                    rules={[
                                        {
                                            message:
                                                "Password must be 8 or more characters.",
                                            min: 8,
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        autoComplete="new-password"
                                        className="br-5"
                                        readOnly={formPasswordSubmitting}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="mb-10"
                                    dependencies={["newPassword"]}
                                    //hasFeedback={formPasswordValues.newPassword.length >= 8 && formPasswordValues.confirmNewPassword.length > 0}
                                    label="Confirm new password"
                                    name="confirmNewPassword"
                                    rules={[
                                        {
                                            message: "Passwords must match",
                                            validator: (_, value) =>
                                                formPasswordValues.newPassword
                                                    .length < 8 ||
                                                value ===
                                                    formPasswordValues.newPassword
                                                    ? Promise.resolve()
                                                    : Promise.reject(),
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        autoComplete="new-password"
                                        className="br-5"
                                        readOnly={formPasswordSubmitting}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="mb-0 text-right"
                                    wrapperCol={{ md: { offset: 8 } }}
                                >
                                    <Space>
                                        <Button
                                            className="br-5"
                                            disabled={
                                                formPasswordUnchanged ||
                                                formPasswordSubmitting
                                            }
                                            htmlType="reset"
                                            type="default"
                                        >
                                            Reset
                                        </Button>
                                        <Button
                                            className="br-5"
                                            disabled={
                                                formPasswordUnchanged ||
                                                formPasswordValues.password
                                                    .length === 0 ||
                                                formPasswordValues.newPassword
                                                    .length < 8 ||
                                                formPasswordValues
                                                    .confirmNewPassword.length <
                                                    8
                                            }
                                            htmlType="submit"
                                            loading={formPasswordSubmitting}
                                            type="primary"
                                        >
                                            Update
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </>
                    )}
                </Card>
            </div>
        </>
    );
}

function isAdult(dateOfBirth: string): boolean {
    // Cover all time zones by requiring date of birth to be 18 years + 1 day old
    const eighteenYearsPlusOneDayAgo = moment
        .utc()
        .subtract(1, "year")
        .subtract(1, "day");

    return moment(dateOfBirth).isBefore(eighteenYearsPlusOneDayAgo);
}

function getPasswordStrength(password: string): undefined | PasswordStrength {
    if (password.length < 8) return undefined;

    let score = 0;
    if (password.match(/(?=.*[a-z])/) !== null) score += 1; // has at least one lowercase letter
    if (password.match(/(?=.*[A-Z])/) !== null) score += 1; // has at least one uppercase letter
    if (password.match(/(?=.*[!@#$&*])/) !== null) score += 1; // has at least one special character
    if (password.match(/(?=.*[0-9])/) !== null) score += 1; // has at least one number character
    if (password.length >= 12) score += 1; // has at least a length of 12

    switch (score) {
        case 1:
            return "very weak";
        case 2:
            return "weak";
        case 3:
            return "moderate";
        case 4:
            return "strong";
        case 5:
            return "very strong";
        default:
            return undefined;
    }
}

function renderPasswordStrengthBar(passwordStrength: PasswordStrength) {
    let percent: number;
    switch (passwordStrength) {
        case "very weak":
            percent = 20;
            break;
        case "weak":
            percent = 40;
            break;
        case "moderate":
            percent = 60;
            break;
        case "strong":
            percent = 80;
            break;
        case "very strong":
            percent = 100;
            break;
    }

    return (
        <Space direction="vertical" size={0}>
            <Progress
                percent={percent}
                showInfo={false}
                status={
                    passwordStrength === "very weak" ||
                    passwordStrength === "weak"
                        ? "exception"
                        : passwordStrength === "moderate"
                        ? "normal"
                        : passwordStrength === "strong" ||
                          passwordStrength === "very strong"
                        ? "success"
                        : "active"
                }
            />
            <Space className="w-100" size="small">
                <Typography.Text type="secondary">
                    Password strength:
                </Typography.Text>
                <Typography.Text
                    type={
                        passwordStrength === "very weak" ||
                        passwordStrength === "weak"
                            ? "danger"
                            : passwordStrength === "moderate"
                            ? "warning"
                            : passwordStrength === "strong" ||
                              passwordStrength === "very strong"
                            ? "success"
                            : "secondary"
                    }
                >
                    {passwordStrength}
                </Typography.Text>
            </Space>
        </Space>
    );
}

export default SettingsPage;
