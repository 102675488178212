import RetweetOutlined from "@ant-design/icons/RetweetOutlined";
import Image from "antd/lib/image";
import Typography from "antd/lib/typography";
import React, { Fragment, useContext, useEffect } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import LayoutContext from "~context/Layout";
import august_keypad_png from "~assets/yale-lock.png";

function FAQPage(): JSX.Element {
    const layout = useContext(LayoutContext);

    useEffect(() => {
        if (layout.data && layout.actions) {
            if (layout.data.header !== true) layout.actions.updateHeader(true);
            if (layout.data.footer !== true) layout.actions.updateFooter(true);
        }
    }, [layout.data, layout.actions]);

    return (
        <Fragment>
            <Helmet>
                <title>FAQ - Twenty4 Tan Co.</title>
            </Helmet>

            <div className="container-md my-50 px-50 text-center-md prose">
                <Typography.Title level={2}>
                    Frequently Asked Questions
                </Typography.Title>
                <Typography.Paragraph className="mb-0">
                    All Twenty4 Tan Co. locations are secured with a smart lock
                    on the front door. Enter your access code on the lock&#39;s
                    keypad, then press the checkmark to enter the building.
                </Typography.Paragraph>
                <Image
                    className="mt-10 mb-25 br-10"
                    preview={false}
                    src={august_keypad_png}
                    style={{ maxWidth: "100%" }}
                    width={200}
                />
                <Typography.Title level={4}>
                    How can I join?
                </Typography.Title>
                <Typography.Paragraph>
                    You must have a valid Twenty4 Tan Co.{" "}
                    <Link to="/membership">membership</Link>. Once you are
                    subscribed, you will be able to view your entry code from
                    the <Link to="/access-code">access code page</Link>.
                </Typography.Paragraph>
                <Typography.Title level={4}>
                    How do memberships work?
                </Typography.Title>
                <Typography.Paragraph>
                    Memberships allow you to subscribe or unsubscribe whenever
                    you need to, all at the ease of your fingertips! Memberships
                    are $55 per month.
                    {/*<div className="mb-5" />
                    Cancelling your membership will refund a prorated amount back to your original payment method.
                    The amount depends on the number of days remaining in the current billing cycle.*/}
                </Typography.Paragraph>
                <Typography.Title level={4}>
                    How do I cancel my membership?
                </Typography.Title>
                <Typography.Paragraph>
                    Sign in to your account using the &apos;Sign In&apos; button
                    in the top right of the website. Once signed in, click on
                    your email in the top right corner, then click the
                    &apos;Membership&apos; option from the drop-down menu. Click
                    the &apos;Unsubscribe&apos; button near the middle of the
                    screen. This will <strong><em>immediately</em></strong> cancel your
                    subscription, you will no longer have access to the salon.
                    If you had any remaining time on your month&apos;s
                    subscription, it is saved as a credit on your account. Next
                    time that you subscribe, it will take off your credit amount
                    and only charge you for the difference of the month.
                </Typography.Paragraph>
                <Typography.Title level={4}>
                    What happens if I cancel my membership early?
                </Typography.Title>
                <Typography.Paragraph>
                    Cancelling your membership will result in a credit on your
                    account that can be used the next time you subscribe.
                    Monetary refunds will not be given.
                </Typography.Paragraph>
                <Typography.Title level={4}>
                    What if my access code was denied?
                </Typography.Title>
                <Typography.Paragraph>
                    Make sure that you are pressing the red button at the bottom
                    of the keypad after entering your 4-digit code.
                    <div className="mb-5" />
                    If your code is not working, visit the{" "}
                    <Link to="/access-code">access code page</Link> and click on
                    the{" "}
                    <Typography.Text keyboard={true}>
                        <RetweetOutlined />
                    </Typography.Text>{" "}
                    button to refresh your code. If it still doesn&apos;t work,
                    please contact us at{" "}
                    <Typography.Link
                        href="mailto:twenty4tanco@gmail.com?subject=Access Code Assistance"
                        rel="noreferrer"
                        target="_blank"
                    >
                        twenty4tanco@gmail.com
                    </Typography.Link>
                    .
                </Typography.Paragraph>
                <Typography.Title level={4}>
                    What should I do if the lock is not working?
                </Typography.Title>
                <Typography.Paragraph>
                    If the lock on a building is unpowered, not responding, or
                    damaged, please <Link to="/contact">contact us</Link> to
                    report it. Please also include the address of the location.
                </Typography.Paragraph>
            </div>
        </Fragment>
    );
}

export default FAQPage;
