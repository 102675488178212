import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import message from 'antd/lib/message';
import PageHeader from 'antd/lib/page-header';
import React, {
    Fragment,
    useContext,
    useEffect,
    useState
} from 'react';
import Helmet from 'react-helmet';
import {
    Link,
    useLocation,
    useNavigate
} from 'react-router-dom';
import qs from 'qs';
import config from '~config';
import LayoutContext from '~context/Layout';
import UserContext from '~context/User';
import Style from './LoginPage.module.less';

type LoginEmailFormValues = {
    emailAddress: string;
    password: string;
};

function LoginPage(): JSX.Element {
    const location = useLocation();
    const navigate = useNavigate();
    const layout = useContext(LayoutContext);
    const user = useContext(UserContext);

    const [loginEmailForm] = Form.useForm<LoginEmailFormValues>();
    const [loginEmailFormSubmitting, setLoginEmailFormSubmitting] = useState<boolean>(false);
    const [loginEmailFormError, setLoginEmailFormError] = useState<string>();

    useEffect(() => {
        if (layout.data && layout.actions) {
            if (layout.data.header !== true) layout.actions.updateHeader(true);
            if (layout.data.footer !== true) layout.actions.updateFooter(true);
        }
    }, [layout.actions, layout.data]);

    useEffect(() => {
        if (user.data) navigate('/');
    }, [user.data]);

    return (
        <Fragment>
            <Helmet>
                <title>Login - Twenty4 Tan Co.</title>
            </Helmet>

            <div className="container-xs my-50 px-50 flex flex-align-center flex-justify-center">
                <div className="w-100">
                    <Card
                        actions={[
                            <Button
                                className="br-10"
                                key="sign-up-button"
                                type="link"
                                onClick={() => navigate('/register')}
                            >
                                Create an account
                            </Button>
                        ]}
                        className={`${Style.loginCard} w-100 br-10 b-light-1 bs-grey-5`}
                        extra={(
                            <Link to="/reset-password">
                                Forgot your password?
                            </Link>
                        )}
                        title={(
                            <PageHeader
                                className="p-0"
                                title="Sign in"
                            />
                        )}
                    >
                        <Form
                            colon={false}
                            form={loginEmailForm}
                            requiredMark={false}
                            validateTrigger="onSubmit"
                            onFinish={async values => {
                                if (!loginEmailFormSubmitting) {
                                    setLoginEmailFormSubmitting(true);
                                    try {
                                        const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/login`, {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            credentials: 'include',
                                            body: JSON.stringify(values)
                                        });

                                        const result: HttpResult = await response.json();
                                        if (result.status === 'success') {
                                            const query = qs.parse(location.search, { ignoreQueryPrefix: true });
                                            if (query.redirect && typeof query.redirect === 'string') window.location.href = query.redirect;
                                            else window.location.href = '/';
                                        } else {
                                            const error = result.message || 'Failed to login';
                                            message.error(error);
                                            setLoginEmailFormError(error);
                                        }
                                    } catch (e) {
                                        console.error(e);
                                        message.error('Failed to login');
                                        setLoginEmailFormError('Failed to login');
                                    }

                                    setLoginEmailFormSubmitting(false);
                                }
                            }}
                        >
                            <Form.Item
                                name="emailAddress"
                                required={true}
                                rules={[
                                    { message: 'Email address is required.', required: true }
                                ]}
                            >
                                <Input
                                    autoComplete="username"
                                    className="br-5"
                                    placeholder="Email address"
                                    type="email"
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                required={true}
                                rules={[
                                    { message: 'Password is required.', required: true }
                                ]}
                            >
                                <Input.Password
                                    autoComplete="current-password"
                                    className="br-5"
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <Form.Item
                                className="mb-0"
                            >
                                <Button
                                    block={true}
                                    className="br-5"
                                    htmlType="submit"
                                    loading={loginEmailFormSubmitting}
                                    type="primary"
                                >
                                    Sign in
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
        </Fragment>
    );
}

export default LoginPage;
