import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import Button from 'antd/lib/button';
import Typography from 'antd/lib/typography';
import React, {
    Fragment,
    useContext,
    useEffect
} from 'react';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import LayoutContext from '~context/Layout';
import Style from './HomePage.module.less';

import hallway_png from '~assets/locations/moberly/hallway.png';
import laying_bed1_png from '~assets/locations/moberly/laying-bed1.png';
import laying_bed2_png from '~assets/locations/moberly/laying-bed2.png';
import lobby1_png from '~assets/locations/moberly/lobby1.png';
import lobby2_png from '~assets/locations/moberly/lobby2.png';
import standing_bed_png from '~assets/locations/moberly/standing-bed.png';
import window_png from '~assets/window.png';

function HomePage(): JSX.Element {
    const navigate = useNavigate();
    const layout = useContext(LayoutContext);

    useEffect(() => {
        if (layout.data && layout.actions) {
            if (layout.data.header !== true) layout.actions.updateHeader(true);
            if (layout.data.footer !== true) layout.actions.updateFooter(true);
        }
    }, [layout.actions, layout.data]);

    return (
        <Fragment>
            <Helmet>
                <title>Home - Twenty4 Tan Co.</title>
            </Helmet>

            <div className={Style.home}>
                <div className={Style.homeBanner}>
                    <div className={Style.homeBannerContent}>
                        <div className={Style.homeBannerContentText}>
                            <Typography.Title level={1}>
                                Tanning that fits your schedule
                            </Typography.Title>
                            <Typography.Title level={3}>
                                Welcome to Twenty4 Tan Co., Missouri’s best 24-hour tanning salon! Enjoy access to high-quality
                                bronzing tanning beds on your own schedule.
                                <br /><br />
                                No appointments, no punch-card.
                                <br /><br />
                                We offer the ease of monthly memberships that can be canceled when you want and access to the salon
                                any time of day. Feel safe with video surveillance in the common areas and knowing that only members
                                will have access to the salon.
                                <br /><br />
                                This is a subscription service, similar to Netflix. It will automatically renew and charge your card
                                every month until you cancel.
                            </Typography.Title>
                            <Button
                                className="mt-25"
                                icon={<ArrowRightOutlined />}
                                shape="round"
                                size="large"
                                type="primary"
                                onClick={() => navigate('/how-it-works')}
                            >
                                Learn more
                            </Button>
                        </div>
                    </div>
                    <div className={Style.homeBannerImages}>
                        <img src={lobby1_png} />
                        <img src={standing_bed_png} />
                        <img src={lobby2_png} />
                        <img src={window_png} />
                        <img src={laying_bed2_png} />
                        <img src={hallway_png} />
                        <img src={laying_bed1_png} />
                    </div>
                </div>

                {/*<div className="bs-inset-grey-5" style={{ background: '#efefef' }}>
                    <div className="container-xl px-50 text-center-md">
                        <div className={Style.joinOurNewsletter}>
                            <Typography.Title
                                className="font-weight-400"
                                level={3}
                            >
                                <Space align="center">
                                    Join our Newsletter
                                    <ContainerOutlined />
                                </Space>
                            </Typography.Title>
                            <Row
                                align="middle"
                                gutter={5}
                                wrap={false}
                            >
                                <Col flex="auto">
                                    <Input
                                        className="br-10 flex-grow-1"
                                        placeholder="jane.doe@example.com"
                                        size="large"
                                        type="email"
                                    />
                                </Col>
                                <Col>
                                    <Button
                                        className="br-10"
                                        icon={<ArrowRightOutlined />}
                                        size="large"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>*/}
            </div>
        </Fragment>
    );
}

export default HomePage;
