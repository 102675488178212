import Card from 'antd/lib/card';
import Input from 'antd/lib/input';
import List from 'antd/lib/list';
import PageHeader from 'antd/lib/page-header';
import React, {
    Fragment,
    useContext,
    useEffect,
    useState
} from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import LayoutContext from '~context/Layout';
import LocationsContext from '~context/Locations';

function LocationsPage(): JSX.Element {
    const layout = useContext(LayoutContext);
    const locations = useContext(LocationsContext);

    const [locationsSearch, setLocationsSearch] = useState<string>('');

    useEffect(() => {
        if (layout.data && layout.actions) {
            if (layout.data.header !== true) layout.actions.updateHeader(true);
            if (layout.data.footer !== true) layout.actions.updateFooter(true);
        }
    }, [layout.actions, layout.data]);

    const locationsList = locations.data ? locations.data.filter(location => {
        const searchValue = locationsSearch.toLowerCase();
        let match = false;
        if (searchValue.length === 0) match = true;
        else if (location.name.toLowerCase().includes(searchValue)) match = true;
        //else if (location.description && location.description.toLowerCase().includes(searchValue)) match = true;
        else if (location.address && location.address.toLowerCase().includes(searchValue)) match = true;
        else if (location.city && location.city.toLowerCase().includes(searchValue)) match = true;
        else if (location.state && location.state.toLowerCase().includes(searchValue)) match = true;
        else if (location.postal_code && location.postal_code.includes(searchValue)) match = true;
        if (match) return location;
    }) : undefined;

    return (
        <Fragment>
            <Helmet>
                <title>Locations - Twenty4 Tan Co.</title>
            </Helmet>

            <div className="container-md my-50 px-50">
                <Card
                    className="br-10 bs-grey-5"
                    title={(
                        <PageHeader
                            className="p-0"
                            title="Locations"
                        />
                    )}
                >
                    <Input
                        className="br-5 mb-10"
                        placeholder="Search"
                        value={locationsSearch}
                        onChange={e => setLocationsSearch(e.target.value)}
                    />
                    <List
                        bordered={true}
                        className="br-5"
                        dataSource={locationsList}
                        loading={locations.data === undefined}
                        locale={{
                            emptyText: 'No locations found'
                        }}
                        renderItem={(location, i) => (
                            <List.Item key={i}>
                                <List.Item.Meta
                                    description={location.address && location.city && location.state && location.postal_code && (
                                        <Fragment>
                                            {location.address}{location.address2 ? `, ${location.address2}` : ''}
                                            <br />
                                            {location.city}, {location.state} {location.postal_code}
                                        </Fragment>
                                    )}
                                    title={(
                                        <Link to={`/locations/${location.id}`}>
                                            {location.name}
                                        </Link>
                                    )}
                                />
                            </List.Item>
                        )}
                    />
                </Card>
            </div>
        </Fragment>
    );
}

export default LocationsPage;
