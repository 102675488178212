import MailOutlined from '@ant-design/icons/MailOutlined';
import PhoneOutlined from '@ant-design/icons/PhoneOutlined';
import Image from 'antd/lib/image';
import Skeleton from 'antd/lib/skeleton';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import RcFooter from 'rc-footer';
import React, {
    Fragment
} from 'react';
import { Link } from 'react-router-dom';
import { FooterProps } from './Footer.types';
import logoWhite from '~assets/logo-white.png';

function Footer(props: FooterProps): JSX.Element {
    return (
        <div className="w-100 bg-black">
            <div className="container-xxl px-25">
                <RcFooter
                    bottom={(
                        <Space
                            size="small"
                            split={<Fragment>&middot;</Fragment>}
                        >
                            <Link
                                to="/privacy"
                                onClick={() => window.scrollTo(0, 0)}
                            >
                                <small>Privacy</small>
                            </Link>
                            <Link
                                to="/terms"
                                onClick={() => window.scrollTo(0, 0)}
                            >
                                <small>Terms</small>
                            </Link>
                            <Link
                                to="/disclaimer"
                                onClick={() => window.scrollTo(0, 0)}
                            >
                                <small>Disclaimer</small>
                            </Link>
                        </Space>
                    )}
                    columnLayout="space-between"
                    columns={[
                        {
                            items: [
                                {
                                    icon: <MailOutlined />,
                                    openExternal: true,
                                    title: 'twenty4tanco@gmail.com',
                                    url: 'mailto:twenty4tanco@gmail.com'
                                },
                                {
                                    icon: <PhoneOutlined />,
                                    title: '+1 (573) 682-7146',
                                    url: 'tel:+15736827146'
                                }
                            ],
                            title: (
                                <Space align="start" size="large">
                                    <Image
                                        height={48}
                                        width={69}
                                        preview={false}
                                        src={logoWhite}
                                    />
                                    <Space direction="vertical" size={0}>
                                        <Typography.Text className="c-light">
                                            Twenty4 Tan Co.
                                        </Typography.Text>
                                        <Typography.Text className="c-light">
                                            <small>&copy; Copyright {new Date().getFullYear()}</small>
                                        </Typography.Text>
                                    </Space>
                                </Space>
                            )
                        },
                        {
                            items: [
                                ...(props.locations ? props.locations.slice(0, 3).map(location => ({
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    LinkComponent: 'div' as any,
                                    direction: 'vertical',
                                    title: (
                                        <Space
                                            className="c-light lh-1"
                                            direction="vertical"
                                            size={0}
                                        >
                                            <Link
                                                className="lh-1_5"
                                                to={`/locations/${location.id}`}
                                                onClick={() => window.scrollTo(0, 0)}
                                            >
                                                {location.name}
                                            </Link>
                                            <small>
                                                {location.address}{location.address2 ? `, ${location.address2}` : ''}
                                            </small>
                                            <small>
                                                {location.city}, {location.state} {location.postal_code}
                                            </small>
                                        </Space>
                                    )
                                })) : [
                                    {
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        LinkComponent: 'div' as any,
                                        title: (
                                            <Skeleton
                                                active={true}
                                                loading={true}
                                                paragraph={{
                                                    rows: 2
                                                }}
                                                title={false}
                                            />
                                        )
                                    }
                                ]),
                                {
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    LinkComponent: 'div' as any,
                                    title: (
                                        <Link
                                            to="/locations"
                                            onClick={() => window.scrollTo(0, 0)}
                                        >
                                            See all locations
                                        </Link>
                                    )
                                }
                            ],
                            title: 'Locations'
                        }
                    ]}
                    theme="dark"
                />
            </div>
        </div>
    );
}

export default Footer;
