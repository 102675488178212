import RetweetOutlined from '@ant-design/icons/RetweetOutlined';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import message from 'antd/lib/message';
import PageHeader from 'antd/lib/page-header';
import Popconfirm from 'antd/lib/popconfirm';
import Space from 'antd/lib/space';
import Statistic from 'antd/lib/statistic';
import Typography from 'antd/lib/typography';
import React, {
    Fragment,
    useContext,
    useEffect,
    useState
} from 'react';
import Helmet from 'react-helmet';
import config from '~config';
import LayoutContext from '~context/Layout';
import UserContext from '~context/User';
import Locations from '~context/Locations';
import LocationsContext from '~context/Locations/LocationsContext';

function AccessCodePage(): JSX.Element {
    const layout = useContext(LayoutContext);
    const locations = useContext(LocationsContext)
    const user = useContext(UserContext);

    const [accessCode, setAccessCode] = useState<string>();
    const [accessCodeFetching, setAccessCodeFetching] = useState<boolean>(false);
    const [accessCode402, setAccessCode402] = useState<boolean>(false);
    const [accessCodeRefreshing, setAccessCodeRefreshing] = useState<boolean>(false);

    useEffect(() => {
        if (layout.data && layout.actions) {
            if (layout.data.header !== true) layout.actions.updateHeader(true);
            if (layout.data.footer !== true) layout.actions.updateFooter(true);
        }
    }, [layout.actions, layout.data]);

    useEffect(() => {
        if (user.data) getUserAccessCode();
    }, [user.data]);

    return (
        <Fragment>
            <Helmet>
                <title>Access Code - Twenty4 Tan Co.</title>
            </Helmet>

            <div className="container-md my-50 px-50">
                {user.data && (
                    <Card
                        className="br-10 bs-grey-5"
                        extra={(
                            <Popconfirm
                                cancelText="No"
                                disabled={accessCodeFetching || accessCode402}
                                okButtonProps={{
                                    loading: accessCodeRefreshing
                                }}
                                okText="Yes"
                                placement="bottomRight"
                                title="Refresh access code?"
                                onConfirm={() => refreshUserAccessCode()}
                            >
                                <Button
                                    disabled={accessCodeFetching || accessCode402 || accessCodeRefreshing}
                                    icon={<RetweetOutlined />}
                                    shape="circle"
                                    title="Refresh access code"
                                    type="default"
                                />
                            </Popconfirm>
                        )}
                        title={(
                            <PageHeader
                                className="p-0"
                                title="Access Code"
                            />
                        )}
                    >
                        <div
                            className="loading"
                            data-loading={accessCodeFetching || accessCodeRefreshing}
                        >
                            <div className="loading-loader z-index--1">
                                <Space>
                                    <LoadingOutlined />
                                    <Typography.Text type="secondary">
                                        {accessCodeFetching ? 'Getting access code...' : 'Refreshing access code...'}
                                    </Typography.Text>
                                </Space>
                            </div>
                            <Statistic
                                className="loading-content text-center z-index-1"
                                groupSeparator=""
                                title={accessCode
                                    ? `Use this code to get into the Twenty4 Tan Co. ${locations.data?.find(l => l.id === user.data?.homeLocation)?.name} location`
                                    : (!accessCode && !accessCodeFetching && !accessCodeRefreshing)
                                        ? accessCode402
                                            ? 'You must purchase to a subscription plan to get an access code'
                                            : 'No access code available, please try refreshing a new access code'
                                        : <Fragment>&nbsp;</Fragment>}
                                value={accessCode ? accessCode : accessCode402 ? 'Membership required' : 'Not available'}
                            />
                        </div>
                    </Card>
                )}
            </div>
        </Fragment>
    );

    async function getUserAccessCode() {
        if (!accessCodeFetching) {
            setAccessCodeFetching(true);
            await new Promise(resolve => setTimeout(resolve, 1000));
            try {
                const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/access/code`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                if (response.status === 402) {
                    setAccessCode402(true);
                } else {
                    const result: HttpResult<string> = await response.json();
                    if (result.status === 'success' && result.data) setAccessCode(result.data);
                }
            } catch (e) {
                console.error(e);
            }
            setAccessCodeFetching(false);
        }
    }

    async function refreshUserAccessCode() {
        if (!accessCodeRefreshing) {
            setAccessCodeRefreshing(true);
            setAccessCode(undefined);
            await new Promise(resolve => setTimeout(resolve, 2500));
            try {
                const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/access/code/refresh`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                if (response.ok) {
                    const result: HttpResult<string> = await response.json();
                    if (result.status === 'success') setAccessCode(result.data);
                    else message.error(result.message || 'Failed to refresh access code.', 1);
                } else {
                    message.error('Failed to refresh access code.', 1);
                }
            } catch (e) {
                console.error(e);
                message.error('Failed to refresh access code.', 1);
            }

            setAccessCodeRefreshing(false);
        }
    }
}

export default AccessCodePage;
