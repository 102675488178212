import { ErrorBoundary } from '@sentry/react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import Alert from 'antd/lib/alert';
import Button from 'antd/lib/button';
import Collapse from 'antd/lib/collapse';
import Layout from 'antd/lib/layout';
import notification from 'antd/lib/notification';
import Result from 'antd/lib/result';
import Typography from 'antd/lib/typography';
import React, {
    Fragment,
    useEffect,
    useState
} from 'react';
import {
    BrowserRouter,
    Route,
    Routes
} from 'react-router-dom';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import Footer from '~components/Footer';
import Header from '~components/Header';
import PrivateRoute from '~components/PrivateRoute';
import LayoutContext, { LayoutContextData } from '~context/Layout';
import LocationsContext, { LocationsContextData } from '~context/Locations';
import StripeContext from '~context/Stripe';
import UserContext from '~context/User';
import NotificationsContext from '~context/Notifications';
import config from '~config';
import Location from '~types/Location';
import Notification from '~types/Notification';
import User from '~types/User';

/* Pages */
import AboutUsPage from './pages/About';
import AccessCodePage from './pages/AccessCode';
import AdministrationPage from './pages/Administration';
import ContactPage from './pages/Contact';
import DisclaimerPage from './pages/Disclaimer';
import FAQPage from './pages/FAQ';
import HomePage from './pages/Home';
import HowItWorksPage from './pages/HowItWorks'
import LocationPage from './pages/Location';
import LocationsPage from './pages/Locations';
import LoginPage from './pages/Login';
import MembershipPage from './pages/Membership';
import NotFoundPage from './pages/NotFound';
import NotificationsPage from './pages/Notifications';
import PrivacyPage from './pages/Privacy';
import RegisterPage from './pages/Register';
import ResetPasswordPage from './pages/ResetPassword';
import SettingsPage from './pages/Settings';
import TermsPage from './pages/Terms';

const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);

function App(): JSX.Element {
    const [initializing, setInitializing] = useState<boolean>(true);

    const [locations, setLocations] = useState<LocationsContextData>();
    const [locationsFetching, setLocationsFetching] = useState<boolean>(false);

    const [user, setUser] = useState<User>();
    const [userFetching, setUserFetching] = useState<boolean>(false);

    const [notifications, setNotifications] = useState<Notification[]>();
    const [notificationsFetching, setNotificationsFetching] = useState<boolean>(false);
    const [notificationsWs, setNotificationsWs] = useState<W3CWebSocket>();

    const [layout, setLayout] = useState<LayoutContextData>({
        header: true,
        footer: true
    });

    const [stripe, setStripe] = useState<Stripe | null>(null);

    useEffect(() => {
        if (initializing) {
            Promise.all([
                getLocations(),
                getUser(),
                getNotifications()
            ]).finally(() => setInitializing(false));
            stripePromise.then(_stripe => {
                if (_stripe) setStripe(_stripe);
            });
        }
    }, [initializing]);

    useEffect(() => {
        if (notifications && notificationsWs) {
            notificationsWs.onmessage = message => {
                const newNotifications = JSON.parse(message.data.toString()) as Notification[];
                newNotifications.forEach(n => {
                    notification[n.type]({
                        message: n.title,
                        description: n.message,
                        duration: 5
                    });
                });
                setNotifications([...newNotifications, ...notifications]);
            }
        }
    }, [notifications, notificationsWs]);

    return (
        <Fragment>
            <BrowserRouter>
                <ErrorBoundary fallback={({ componentStack, error, eventId }) => {
                    return (
                        <div className="error-boundary">
                            <div className="container-lg my-50 px-50">
                                <Result
                                    className="w-100"
                                    extra={(
                                        <Button
                                            className="br-5"
                                            type="primary"
                                            onClick={() => window.location.reload()}
                                        >
                                            Refresh Page
                                        </Button>
                                    )}
                                    status="error"
                                    subTitle={(
                                        <Fragment>
                                            <Typography.Text type="secondary">Event ID:</Typography.Text>&nbsp;
                                            <Typography.Text copyable={true} type="danger">{eventId}</Typography.Text>
                                        </Fragment>
                                    )}
                                    title="Uh oh, something went wrong"
                                >
                                    <Collapse ghost={true}>
                                        <Collapse.Panel header="Error info" key="stacktrace">
                                            <Alert
                                                message={`${error.name}: ${error.message}`}
                                                type="error"
                                            />
                                            <pre>
                                                <code>{componentStack}</code>
                                            </pre>
                                        </Collapse.Panel>
                                    </Collapse>
                                </Result>
                            </div>
                        </div>
                    );
                }}>
                    <LocationsContext.Provider value={{
                        data: locations
                    }}>
                        <UserContext.Provider value={{
                            initializing,
                            data: user,
                            logout: async () => {
                                try {
                                    const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/logout`, {
                                        cache: 'no-cache',
                                        method: 'POST',
                                        credentials: 'include'
                                    });

                                    if (response.ok) {
                                        window.location.href = '/';
                                    } else {

                                    }
                                } catch (e) {

                                }
                            },
                            set: user ? data => setUser({
                                ...user,
                                ...data
                            }) : undefined
                        }}>
                            <NotificationsContext.Provider value={{
                                data: notifications,
                                add: notifications ? (notification) => {
                                    setNotifications([notification, ...notifications]);
                                } : undefined,
                                update: notifications ? (notification) => {
                                    const otherNotifications = notifications.filter(n => n.id !== notification.id);
                                    setNotifications([notification, ...otherNotifications]);
                                } : undefined,
                                delete: notifications ? (id) => {
                                    const otherNotifications = notifications.filter(n => n.id !== id);
                                    setNotifications(otherNotifications);
                                } : undefined
                            }}>
                                <StripeContext.Provider value={stripe}>
                                    <LayoutContext.Provider value={{
                                        data: layout,
                                        actions: {
                                            updateHeader: header => setLayout({ ...layout, header }),
                                            updateFooter: footer => setLayout({ ...layout, footer })
                                        }
                                    }}>
                                        <Layout className="app">
                                            {typeof layout.header === 'boolean' ? layout.header === true ? (
                                                <Layout.Header className="app-header">
                                                    <Header />
                                                </Layout.Header>
                                            ) : undefined : (
                                                <Layout.Header className="app-header">
                                                    {layout.header}
                                                </Layout.Header>
                                            )}
                                            <Layout.Content className="app-content">
                                                {/*<Suspense fallback="Loading...">*/}
                                                <Routes>
                                                    {/* Public Routes */}
                                                    <Route path="/" element={<HomePage />} />
                                                    <Route path="/how-it-works" element={<HowItWorksPage />} />
                                                    <Route path="/about" element={<AboutUsPage />} />
                                                    <Route path="/contact" element={<ContactPage />} />
                                                    <Route path="/faq" element={<FAQPage />} />
                                                    <Route path="/locations" element={<LocationsPage />} />
                                                    <Route path="/locations/:id" element={<LocationPage />} />
                                                    <Route path="/privacy" element={<PrivacyPage />} />
                                                    <Route path="/terms" element={<TermsPage />} />
                                                    <Route path="/disclaimer" element={<DisclaimerPage />} />

                                                    {/* Auth Routes */}
                                                    <Route path="/register" element={<RegisterPage />} />
                                                    <Route path="/login" element={<LoginPage />} />
                                                    <Route path="/reset-password" element={<ResetPasswordPage />} />

                                                    {/* Private Routes */}
                                                    <Route
                                                        path="/notifications"
                                                        element={<PrivateRoute element={<NotificationsPage />} />}
                                                    />
                                                    <Route
                                                        path="/administration"
                                                        element={<PrivateRoute element={<AdministrationPage />} />}
                                                    />
                                                    <Route
                                                        path="/membership"
                                                        element={<PrivateRoute element={<MembershipPage />} />}
                                                    />
                                                    <Route
                                                        path="/access-code"
                                                        element={<PrivateRoute element={<AccessCodePage />} />}
                                                    />
                                                    <Route
                                                        path="/settings"
                                                        element={<PrivateRoute element={<SettingsPage />} />}
                                                    />

                                                    {/* Fallback Route */}
                                                    <Route path="*" element={<NotFoundPage />} />
                                                </Routes>
                                                {/*</Suspense>*/}
                                            </Layout.Content>
                                            {typeof layout.footer === 'boolean' ? layout.footer === true ? (
                                                <Layout.Footer className="app-footer">
                                                    <Footer
                                                        locations={locations}
                                                    />
                                                </Layout.Footer>
                                            ) : undefined : (
                                                <Layout.Footer className="app-footer">
                                                    {layout.header}
                                                </Layout.Footer>
                                            )}
                                        </Layout>
                                    </LayoutContext.Provider>
                                </StripeContext.Provider>
                            </NotificationsContext.Provider>
                        </UserContext.Provider>
                    </LocationsContext.Provider>
                </ErrorBoundary>
            </BrowserRouter>
        </Fragment>
    );

    async function getLocations() {
        if (!locationsFetching) {
            setLocationsFetching(true);
            try {
                const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/locations`, {
                    cache: 'no-cache',
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json'
                    }
                });

                const result: HttpResult<Location[]> = await response.json();
                if (result.status === 'success' && result.data) setLocations(result.data);
            } catch (e) {
                console.error(e);
            }
            setLocationsFetching(false);
        }
    }

    async function getUser() {
        if (!userFetching) {
            setUserFetching(true);
            try {
                const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/user`, {
                    cache: 'no-cache',
                    headers: {
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                const result: HttpResult<User> = await response.json();
                if (result.status === 'success' && result.data) {
                    setUser(result.data);
                    const _notificationsWs = new W3CWebSocket(`${config.TWENTY4TANCO_API_BASE_URL.replace('https://', 'wss://').replace('http://', 'ws://')}/notifications`);
                    setNotificationsWs(_notificationsWs);
                }
            } catch (e) {
                console.error(e);
            }
            setUserFetching(false);
        }
    }

    async function getNotifications() {
        if (!notificationsFetching) {
            setNotificationsFetching(true);
            try {
                const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/notifications`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                const result: HttpResult<Notification[]> = await response.json();
                if (result.status === 'success' && result.data) setNotifications(result.data);
            } catch (e) {
                console.error(e);
            }
            setNotificationsFetching(false);
        }
    }
}

export default App;
