import { useLayoutEffect, useState } from 'react';

/**
 * Hook that tracks the current window width and height.
 * @returns [width, height]
 */
function useWindowSize(): [number, number] {
    const [size, setSize] = useState<[number, number]>([window.innerWidth, window.innerHeight]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
}

export default useWindowSize;
