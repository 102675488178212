import PhoneOutlined from "@ant-design/icons/PhoneOutlined";
import Badge from "antd/lib/badge";
import Button from "antd/lib/button";
import Card from "antd/lib/card";
import Col from "antd/lib/col";
import Image from "antd/lib/image";
import PageHeader from "antd/lib/page-header";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Typography from "antd/lib/typography";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useMatch, useNavigate } from "react-router-dom";
import LayoutContext from "~context/Layout";
import LocationsContext from "~context/Locations";
import Location from "~types/Location";
import Style from "./LocationPage.module.less";

import * as assetsLocations from "~assets/locations";

type LocationInfo =
    | {
          key: string;
          description: string;
          phone?: {
              label: string;
              number: string;
          };
          coordinates?: [number, number];
          images?: {
              src: string;
              alt?: string;
          }[];
      }
    | undefined;

function LocationPage(): JSX.Element {
    const match = useMatch("/locations/:id");
    const navigate = useNavigate();

    const layout = useContext(LayoutContext);
    const locations = useContext(LocationsContext);

    const [location, setLocation] = useState<Location>();

    useEffect(() => {
        if (layout.data && layout.actions) {
            if (layout.data.header !== true) layout.actions.updateHeader(true);
            if (layout.data.footer !== true) layout.actions.updateFooter(true);
        }
    }, [layout.actions, layout.data]);

    useEffect(() => {
        if (match && locations.data) {
            const _location = locations.data.find(
                (o) => o.id === match.params.id
            );
            if (_location) setLocation(_location);
            else navigate("/locations");
        }
    }, [match, locations.data]);

    let locationInfo: LocationInfo | undefined = undefined;
    if (location) locationInfo = getLocationInfo(location.name);

    return (
        <Fragment>
            <Helmet>
                <title>{location?.name || ""} - Twenty4 Tan Co.</title>
            </Helmet>

            <div className="container-md my-50 px-50">
                <Card
                    className="br-10 bs-grey-5"
                    extra={
                        location &&
                        location.phone && (
                            <Button
                                href={`tel:${location.phone}`}
                                style={{ padding: "0" }}
                                type="link"
                            >
                                <Space size={4}>
                                    <PhoneOutlined /> {location.phone}
                                </Space>
                            </Button>
                        )
                    }
                    loading={!location}
                    title={
                        location && (
                            <PageHeader
                                style={{ padding: "0" }}
                                title={location.name}
                                onBack={() => navigate("/locations")}
                            />
                        )
                    }
                >
                    {location && (
                        <Fragment>
                            {locationInfo && (
                                <Typography.Paragraph>
                                    {locationInfo.description}
                                </Typography.Paragraph>
                            )}
                            <Row align="top" gutter={10}>
                                {location.address &&
                                    location.city &&
                                    location.state &&
                                    location.postal_code && (
                                        <Col xs={24} sm={12}>
                                            <Typography.Title level={5}>
                                                Address
                                            </Typography.Title>

                                            <Typography.Paragraph>
                                                {location.address}
                                                {location.address2
                                                    ? `, ${location.address2}`
                                                    : ""}
                                                <br />
                                                {location.city},{" "}
                                                {location.state}{" "}
                                                {location.postal_code}
                                                <br />
                                                <Typography.Link
                                                    href={`https://maps.google.com/?q=${location.address} ${location.city} ${location.state}`}
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >
                                                    View on Google
                                                </Typography.Link>
                                            </Typography.Paragraph>
                                        </Col>
                                    )}
                                <Col xs={24} sm={12}>
                                    <div className="br-5 bs-grey-5">
                                        <div className="br-5 bs-inset-grey-1">
                                            <Image.PreviewGroup>
                                                <Row
                                                    gutter={10}
                                                    style={{
                                                        overflowX: "auto",
                                                    }}
                                                    wrap={false}
                                                >
                                                    {locationInfo?.images?.map(
                                                        (image, i) => (
                                                            <Col
                                                                key={i}
                                                                span={16}
                                                            >
                                                                <Image
                                                                    className="br-5"
                                                                    preview={{
                                                                        maskClassName:
                                                                            "br-5",
                                                                    }}
                                                                    src={
                                                                        image.src
                                                                    }
                                                                    alt={
                                                                        image.alt
                                                                    }
                                                                />
                                                            </Col>
                                                        )
                                                    )}
                                                </Row>
                                            </Image.PreviewGroup>
                                        </div>
                                    </div>
                                </Col>
                                {locationInfo?.coordinates && (
                                    <MapContainer
                                        className={`${Style.map} bs-lightgrey-5`}
                                        center={locationInfo.coordinates}
                                        zoom={18}
                                    >
                                        <TileLayer
                                            subdomains={[
                                                "mt0",
                                                "mt1",
                                                "mt2",
                                                "mt3",
                                            ]}
                                            url="http://{s}.google.com/vt/lyrs=m&amp;x={x}&amp;y={y}&amp;z={z}"
                                        />
                                        <Marker
                                            position={locationInfo.coordinates}
                                        >
                                            <Popup>
                                                Twenty4 Tan Co. -{" "}
                                                {location.name}
                                            </Popup>
                                        </Marker>
                                    </MapContainer>
                                )}
                            </Row>
                        </Fragment>
                    )}
                </Card>
            </div>
        </Fragment>
    );

    function getLocationInfo(name: string): LocationInfo | undefined {
        let locationInfo: LocationInfo | undefined = undefined;
        switch (name.trim()) {
            case "Moberly":
                locationInfo = {
                    key: "moberly",
                    description: `Moberly is the founding location for Twenty4 Tan Co. Come tan in our newly renovated salon!`,
                    phone: {
                        label: "+1 (573) 682-7146",
                        number: "+15736827146",
                    },
                    coordinates: [39.4199437, -92.441642],
                    images: Object.keys(assetsLocations.moberly).map((key) => ({
                        src: assetsLocations.moberly[
                            key as keyof typeof assetsLocations.moberly
                        ],
                    })),
                };
                break;
            case "Macon":
                locationInfo = {
                    key: "macon",
                    description: "",
                    phone: {
                        label: "",
                        number: "",
                    },
                    coordinates: [39.741814, -92.464784],
                    images: Object.keys(assetsLocations.macon).map((key) => ({
                        src: assetsLocations.macon[
                            key as keyof typeof assetsLocations.macon
                        ],
                    })),
                };
                break;
            case "Kirksville":
                locationInfo = {
                    key: "kirksville",
                    description: "",
                    phone: {
                        label: "",
                        number: ""
                    },
                    coordinates: [40.190950,-92.571480],
                    images: Object.keys(assetsLocations.kirksville).map((key) => ({
                        src: assetsLocations.kirksville[
                            key as keyof typeof assetsLocations.kirksville
                        ]
                    }))
                }
        }

        return locationInfo;
    }
}

export default LocationPage;
