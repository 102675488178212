import Typography from 'antd/lib/typography';
import React, {
    Fragment,
    useContext,
    useEffect
} from 'react';
import Helmet from 'react-helmet';
import LayoutContext from '~context/Layout';

function TermsPage(): JSX.Element {
    const layout = useContext(LayoutContext);

    useEffect(() => {
        if (layout.data && layout.actions) {
            if (layout.data.header !== true) layout.actions.updateHeader(true);
            if (layout.data.footer !== true) layout.actions.updateFooter(true);
        }
    }, [layout.actions, layout.data]);

    return (
        <Fragment>
            <Helmet>
                <title>Terms - Twenty4 Tan Co.</title>
            </Helmet>

            <div className="container-xl my-50 px-50">
                <Typography.Title level={1}>Terms and Conditions</Typography.Title>
                <Typography.Paragraph>Last updated: March 27, 2022</Typography.Paragraph>

                <Typography.Title level={2}>Member agreement</Typography.Title>
                <Typography.Paragraph>
                    <strong>
                        I, the Member, certify that I have been informed of, understand and agree to the following:
                    </strong>
                    <ul>
                        <li>
                            The tanning unit emits ultraviolet radiation. As with natural sunlight, overexposure can
                            cause eye and skin injury as well as allergic reactions. Repeated exposure may cause
                            premature aging of skin and skin cancer. A maximum of one cycle of one tanning unit in
                            a twenty-four hour period is allowed.
                        </li>
                        <li>
                            Protective eyewear MUST be worn at all times while using the tanning unit. Failure to
                            wear protective eyewear may result in severe burns or long-term injury to the eyes,
                            including early formation of cataracts and/or temporary or permanent blindness.
                        </li>
                        <li>
                            Medications or cosmetics may increase your sensitivity to ultraviolet radiation. Consult
                            your physician before using the tanning unit to ensure you are not on any medications
                            that could result in increased sensitivity to ultraviolet radiation. Use of tanning unit while
                            on any medication with these side possible side effects is prohibited.
                        </li>
                        <li>
                            If you do not tan in the sun, you are unlikely to tan from the use of the tanning unit.
                        </li>
                        <li>
                            Use of tanning unit during pregnancy is prohibited.
                        </li>
                        <li>
                            The tanning unit must be properly cleaned and sanitized by the member before and after
                            each use.
                        </li>
                        <li>
                            Memberships are meant for each individual. The sharing of your entry code or usage of
                            the tanning units is prohibited. Any violation of this policy will result in permanent
                            cancellation of membership.
                        </li>
                        <li>
                            Members must be at least 18 years of age, have no health problems or sensitivities, and
                            am physically able to use all facilities. Members agree that the facility is not responsible
                            or liable to the member for any injury, accident or loss of personal property.
                        </li>
                        <li>
                            Members must follow all instructional guidelines and to cooperatively use the facility
                            with other members.
                        </li>
                    </ul>
                    <em>
                        Failure to comply with these expectations can result in immediate cancellation of
                        memberships.
                    </em>
                </Typography.Paragraph>

                <Typography.Title level={2}>Use at your own risk</Typography.Title>
                <Typography.Paragraph>
                    <ul>
                        <li>
                            The tanning unit emits ultraviolet radiation. As with natural sunlight, overexposure can
                            cause eye and skin injury as well as allergic reactions. Repeated exposure may cause
                            premature aging of skin and skin cancer. A maximum of one cycle of one tanning unit in
                            a twenty-four hour period is allowed.
                        </li>
                        <li>
                            Protective eyewear MUST be worn at all times while using the tanning unit. Failure to
                            wear protective eyewear may result in severe burns or long-term injury to the eyes,
                            including early formation of cataracts and/or temporary or permanent blindness.
                        </li>
                        <li>
                            Medications or cosmetics may increase your sensitivity to ultraviolet radiation. Consult
                            your physician before using the tanning unit to ensure you are not on any medications
                            that could result in increased sensitivity to ultraviolet radiation. Use of tanning unit while
                            on any medication with these side possible side effects is prohibited.
                        </li>
                        <li>
                            If you do not tan in the sun, you are unlikely to tan from the use of the tanning unit.
                        </li>
                        <li>
                            Use of tanning unit during pregnancy is prohibited.
                        </li>
                    </ul>
                </Typography.Paragraph>
            </div>
        </Fragment>
    )
}

export default TermsPage
