import Checkbox from 'antd/lib/checkbox';
import Table from 'antd/lib/table';
import Typography from 'antd/lib/typography';
import moment from 'moment';
import React from 'react';
import config from '~config';
import { UserTableProps } from './UserTable.types';

function UserTable({ users, loading }: UserTableProps): JSX.Element {
    const usersSorted = users?.sort((a, b) => moment.utc(a.metadata.createdAt).unix() - moment.utc(b.metadata.createdAt).unix());

    return (
        <Table
            columns={[
                {
                    key: 'emailAddress',
                    dataIndex: 'emailAddress',
                    title: 'Email address',
                    width: 250,
                    render: value => (
                        <Typography.Link
                            href={`mailto:${value}`}
                            target="_blank"
                        >
                            {value}
                        </Typography.Link>
                    ),
                    sorter: {
                        compare: (a, b) => a.emailAddress.localeCompare(b.emailAddress)
                    }
                },
                {
                    key: 'administrator',
                    title: 'Administrator?',
                    width: 170,
                    render: (_, record) => (
                        <Checkbox
                            checked={record.administrator}
                            style={{
                                pointerEvents: 'none'
                            }}
                        />
                    ),
                    sorter: {
                        compare: (a, b) => {
                            if (a.administrator && b.administrator) return 0;
                            if (a.administrator) return -1;
                            if (b.administrator) return 1;
                            return 0;
                        }
                    }
                },
                {
                    key: 'subscribed',
                    title: 'Subscribed?',
                    width: 150,
                    render: (_, record) => (
                        <Checkbox
                            checked={record.remoteLockAccessPerson !== null}
                            style={{
                                pointerEvents: 'none'
                            }}
                        />
                    ),
                    sorter: {
                        compare: (a, b) => {
                            if (a.remoteLockAccessPerson && b.remoteLockAccessPerson) return 0;
                            if (a.remoteLockAccessPerson) return -1;
                            if (b.remoteLockAccessPerson) return 1;
                            return 0;
                        }
                    }
                },
                {
                    key: 'name',
                    title: 'Name',
                    width: 200,
                    render: (_, record) => `${record.firstName} ${record.lastName}`,
                    sorter: {
                        compare: (a, b) => (`${a.firstName} ${a.lastName}`).localeCompare(`${b.firstName} ${b.lastName}`)
                    }
                },
                {
                    key: 'dateOfBirth',
                    dataIndex: 'dateOfBirth',
                    title: 'Date of birth',
                    width: 150,
                    render: value => moment.utc(value).toDate().toLocaleDateString(),
                    sorter: {
                        compare: (a, b) => moment.utc(a.dateOfBirth).unix() - moment.utc(b.dateOfBirth).unix()
                    }
                },
                {
                    key: 'phoneNumber',
                    dataIndex: 'phoneNumber',
                    title: 'Phone number',
                    width: 200
                },
                {
                    key: 'stripeCustomer',
                    dataIndex: 'stripeCustomer',
                    title: 'Stripe customer',
                    ellipsis: true,
                    width: 180,
                    render: value => (
                        <Typography.Link
                            href={`https://dashboard.stripe.com/${config.ENVIRONMENT === 'production' ? '' : 'test/'}customers/${value}`}
                            target="_blank"
                        >
                            {value}
                        </Typography.Link>
                    )
                },
                {
                    key: 'remoteLockAccessPerson',
                    dataIndex: 'remoteLockAccessPerson',
                    title: 'RemoteLock access person',
                    ellipsis: true,
                    width: 230,
                    render: value => (
                        <Typography.Link
                            href={`https://connect.remotelock.com/access/user/${value}`}
                            target="_blank"
                        >
                            {value}
                        </Typography.Link>
                    )
                },
                {
                    key: 'createdAt',
                    dataIndex: ['metadata', 'createdAt'],
                    title: 'Created at',
                    width: 190,
                    render: value => moment.utc(value).toDate().toLocaleString(),
                    sorter: {
                        compare: (a, b) => moment.utc(a.metadata.createdAt).unix() - moment.utc(b.metadata.createdAt).unix()
                    }
                },
                {
                    key: 'updatedAt',
                    dataIndex: ['metadata', 'updatedAt'],
                    title: 'Updated at',
                    width: 190,
                    render: value => moment.utc(value).toDate().toLocaleString(),
                    sorter: {
                        compare: (a, b) => moment.utc(a.metadata.updatedAt).unix() - moment.utc(b.metadata.updatedAt).unix()
                    }
                }
            ]}
            dataSource={usersSorted}
            loading={loading}
            rowKey={user => user.id}
            pagination={{
                style: {
                    marginRight: '15px'
                }
            }}
            scroll={{
                y: 400
            }}
        />
    );
}

export default UserTable;
