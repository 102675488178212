import CheckCircleFilled from "@ant-design/icons/CheckCircleFilled";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import InfoCircleFilled from "@ant-design/icons/InfoCircleFilled";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import { PaymentMethod } from "@stripe/stripe-js";
import Button from "antd/lib/button";
import Card from "antd/lib/card";
import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import List from "antd/lib/list";
import message from "antd/lib/message";
import Modal from "antd/lib/modal";
import PageHeader from "antd/lib/page-header";
import Popconfirm from "antd/lib/popconfirm";
import Row from "antd/lib/row";
import Skeleton from "antd/lib/skeleton";
import Space from "antd/lib/space";
import Switch from "antd/lib/switch";
import Tag from "antd/lib/tag";
import Typography from "antd/lib/typography";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import StripePaymentMethodForm from "~components/StripePaymentMethodForm";
import StripeSubscribeForm from "~components/StripeSubscribeForm";
import config from "~config";
import LayoutContext from "~context/Layout";
import Locations from "~context/Locations";
import UserContext from "~context/User";
import StripeProductWithPrice from "~types/StripeProductWithPrice";
import StripeSubscription from "~types/StripeSubscription";
import formatCardBrand from "~utilities/formatCardBrand";
import formatCardExpiry from "~utilities/formatCardExpiry";
import sleep from "~utilities/sleep";
import Style from "./MembershipPage.module.less";

function MembershipPage(): JSX.Element {
    const layout = useContext(LayoutContext);
    const locations = useContext(Locations);
    const user = useContext(UserContext);

    const [product, setProduct] = useState<StripeProductWithPrice>();
    const [productFetching, setProductFetching] = useState<boolean>(false);
    const [productError, setProductError] = useState<string>();

    const [subscription, setSubscription] =
        useState<StripeSubscription | null>();
    const [subscriptionFetching, setSubscriptionFetching] =
        useState<boolean>(false);
    const [subscriptionError, setSubscriptionError] = useState<string>();
    const [subscriptionDeleting, setSubscriptionDeleting] =
        useState<boolean>(false);
    const [
        subscriptionDefaultPaymentMethodUpdating,
        setSubscriptionDefaultPaymentMethodUpdating,
    ] = useState<boolean>(false);

    const [subscribeFormModalVisible, setSubscribeFormModalVisible] =
        useState<boolean>(false);

    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();
    const [paymentMethodsFetching, setPaymentMethodsFetching] =
        useState<boolean>(false);
    const [paymentMethodsError, setPaymentMethodsError] = useState<string>();
    const [paymentMethodDeletingId, setPaymentMethodDeletingId] =
        useState<string>();
    const [paymentMethodFormVisible, setPaymentMethodFormVisible] =
        useState<boolean>(false);

    useEffect(() => {
        if (layout.data && layout.actions) {
            if (layout.data.header !== true) layout.actions.updateHeader(true);
            if (layout.data.footer !== true) layout.actions.updateFooter(true);
        }
    }, [layout.actions, layout.data]);

    let nextChargeAmount: number | undefined = undefined;
    if (
        product &&
        subscription &&
        subscription.items &&
        subscription.items.data &&
        subscription.items.data.length > 0
    ) {
        nextChargeAmount = subscription.items.data[0].price.unit_amount / 100;
        if (subscription.discount) {
            if (subscription.discount.coupon) {
                if (subscription.discount.coupon.amount_off)
                    nextChargeAmount -= subscription.discount.coupon.amount_off;
                else if (subscription.discount.coupon.percent_off) {
                    if (subscription.discount.coupon.percent_off === 100)
                        nextChargeAmount = 0;
                    else
                        nextChargeAmount =
                            nextChargeAmount *
                            ((100 - subscription.discount.coupon.percent_off) /
                                100);
                }
            }
        }
    }

    useEffect(() => {
        if (user.data) {
            if (!product && !productError) getProduct();
            if (!subscription && !subscriptionError) getSubscription();
            if (!paymentMethods && !paymentMethodsError) getPaymentMethods();
        }
    }, [user.data]);

    const pageLoading =
        productFetching || subscriptionFetching || paymentMethodsFetching;

    let subscriptionPaymentMethod: PaymentMethod | undefined = undefined;
    if (subscription && paymentMethods)
        subscriptionPaymentMethod = paymentMethods.find(
            (paymentMethod) =>
                subscription.default_payment_method === paymentMethod.id
        );

    return (
        <Fragment>
            <Helmet>
                <title>Membership - Twenty4 Tan Co.</title>
            </Helmet>

            <div className="container-md my-50 px-50">
                <Card
                    className="br-10 bs-grey-5"
                    title={<PageHeader className="p-0" title="Membership" />}
                >
                    <Skeleton active={true} loading={pageLoading}>
                        <Row align="middle" gutter={10}>
                            <Col flex="auto">
                                <Space align="center">
                                    {subscription === undefined ? (
                                        <Skeleton.Button
                                            active={subscriptionFetching}
                                            size="small"
                                            style={{ width: "140px" }}
                                        />
                                    ) : subscription === null ? (
                                        <Fragment>
                                            <InfoCircleFilled className="font-size-md c-warning" />
                                            <Typography.Text>
                                                Not subscribed
                                            </Typography.Text>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <CheckCircleFilled className="font-size-md c-success" />
                                            <Typography.Text>
                                                Subscribed
                                            </Typography.Text>
                                        </Fragment>
                                    )}
                                </Space>
                            </Col>
                            {subscription !== undefined && (
                                <Col>
                                    <Space align="center">
                                        {subscription !== null ? (
                                            <Popconfirm
                                                cancelText="No"
                                                okText="Yes"
                                                okType="danger"
                                                title="Cancel membership?"
                                                onConfirm={() =>
                                                    deleteSubscription(
                                                        subscription.id
                                                    )
                                                }
                                            >
                                                <Button
                                                    danger={true}
                                                    disabled={
                                                        subscriptionDeleting
                                                    }
                                                    className="br-5"
                                                >
                                                    Cancel
                                                </Button>
                                            </Popconfirm>
                                        ) : (
                                            <Button
                                                className="br-5"
                                                onClick={() =>
                                                    setSubscribeFormModalVisible(
                                                        true
                                                    )
                                                }
                                            >
                                                Subscribe
                                            </Button>
                                        )}
                                    </Space>
                                </Col>
                            )}
                        </Row>
                        {product &&
                            (subscription &&
                            subscription.items &&
                            subscription.items.data &&
                            subscription.items.data.length > 0 ? (
                                <div className="mt-10">
                                    <Typography.Title
                                        className="mb-0 font-weight-400"
                                        level={5}
                                    >
                                        {product.name}
                                        {user.data?.homeLocation != null
                                            ? ` - ${
                                                  locations.data?.find(
                                                      (l) =>
                                                          l.id ===
                                                          user.data
                                                              ?.homeLocation
                                                  )?.name
                                              }`
                                            : ""}
                                    </Typography.Title>
                                    <Typography.Paragraph className="mb-0">
                                        {product.price.nickname} - $
                                        {(
                                            subscription.items.data[0].price
                                                .unit_amount / 100
                                        ).toFixed(2)}{" "}
                                        /{" "}
                                        {product.price.recurring
                                            .interval_count > 1
                                            ? `${product.price.recurring.interval_count} `
                                            : ""}
                                        {product.price.recurring.interval}
                                        {product.price.recurring
                                            .interval_count > 1
                                            ? "s"
                                            : ""}
                                    </Typography.Paragraph>
                                    {subscriptionPaymentMethod &&
                                        subscriptionPaymentMethod.card &&
                                        nextChargeAmount !== undefined && (
                                            <Typography.Paragraph
                                                className="mb-5"
                                                type="secondary"
                                            >
                                                {subscription.discount?.coupon
                                                    ?.duration === "forever"
                                                    ? "Your subscription is free forever!"
                                                    : `Your ${
                                                          formatCardBrand(
                                                              subscriptionPaymentMethod
                                                                  .card.brand
                                                          ).name
                                                      } ending in ${
                                                          subscriptionPaymentMethod
                                                              .card.last4
                                                      } will ${
                                                          nextChargeAmount === 0
                                                              ? "not be charged this billing cycle."
                                                              : `be charged ${nextChargeAmount.toFixed(
                                                                    2
                                                                )} on ${moment
                                                                    .unix(
                                                                        subscription.current_period_end
                                                                    )
                                                                    .toDate()
                                                                    .toLocaleDateString()}`
                                                      }`}
                                            </Typography.Paragraph>
                                        )}
                                    <Typography.Paragraph>
                                        <Link to="/access-code">
                                            View your access code
                                        </Link>
                                    </Typography.Paragraph>
                                </div>
                            ) : (
                                <Typography.Paragraph className="mt-25">
                                    Subscribing is easy! Just add a payment
                                    method below, and click <em>Subscribe</em>{" "}
                                    to get started! Subscriptions are $
                                    {(product.price.unit_amount / 100).toFixed(
                                        2
                                    )}{" "}
                                    per {product.price.recurring.interval}.
                                </Typography.Paragraph>
                            ))}
                    </Skeleton>

                    <Divider />

                    <Row align="middle" gutter={10}>
                        <Col flex="auto">
                            <Typography.Title
                                className="mb-0 font-weight-400"
                                level={5}
                            >
                                Payment Methods
                            </Typography.Title>
                        </Col>
                        <Col>
                            <Button
                                className="br-5"
                                disabled={
                                    paymentMethods === undefined ||
                                    paymentMethodsFetching ||
                                    paymentMethodFormVisible
                                }
                                icon={<PlusOutlined />}
                                onClick={() =>
                                    setPaymentMethodFormVisible(true)
                                }
                            >
                                Add
                            </Button>
                        </Col>
                    </Row>
                    <List
                        bordered={true}
                        className={`${Style.paymentMethods} b-0`}
                        dataSource={
                            paymentMethods
                                ? paymentMethods.sort(
                                      (a, b) =>
                                          moment.unix(b.created).valueOf() -
                                          moment.unix(a.created).valueOf()
                                  )
                                : //.sort(a => subscription ? subscription.default_payment_method === a.id ? -1 : 1 : 0)
                                  undefined
                        }
                        loading={pageLoading}
                        locale={{
                            emptyText: "No payment methods",
                        }}
                        renderItem={(paymentMethod) => {
                            if (paymentMethod.card) {
                                const { icon, name } = formatCardBrand(
                                    paymentMethod.card.brand
                                );

                                return (
                                    <List.Item
                                        actions={[
                                            <Popconfirm
                                                cancelText="No"
                                                disabled={
                                                    subscription?.default_payment_method ===
                                                    paymentMethod.id
                                                }
                                                key="delete-payment-method"
                                                okText="Yes"
                                                okType="danger"
                                                title="Delete payment method?"
                                                onConfirm={() =>
                                                    deletePaymentMethod(
                                                        paymentMethod.id
                                                    )
                                                }
                                            >
                                                <Button
                                                    danger={true}
                                                    disabled={
                                                        subscription?.default_payment_method ===
                                                            paymentMethod.id ||
                                                        paymentMethodDeletingId !==
                                                            undefined
                                                    }
                                                    icon={<DeleteOutlined />}
                                                    type="link"
                                                />
                                            </Popconfirm>,
                                        ]}
                                        className="bb-0 br-5 bs-lightgrey-5"
                                    >
                                        <List.Item.Meta
                                            title={
                                                <Space align="center">
                                                    {icon}
                                                    <Typography.Text>
                                                        {name}
                                                    </Typography.Text>
                                                    {subscription &&
                                                        subscription.default_payment_method ===
                                                            paymentMethod.id && (
                                                            <Tag color="blue">
                                                                default
                                                            </Tag>
                                                        )}
                                                </Space>
                                            }
                                            description={
                                                <Typography.Paragraph
                                                    className="mb-0"
                                                    type="secondary"
                                                >
                                                    <small>
                                                        {`**** **** **** ${paymentMethod.card.last4}`}{" "}
                                                        -{" "}
                                                        {formatCardExpiry(
                                                            paymentMethod.card
                                                                .exp_month,
                                                            paymentMethod.card
                                                                .exp_year
                                                        )}
                                                    </small>
                                                </Typography.Paragraph>
                                            }
                                        />

                                        {subscription && (
                                            <div>
                                                <Popconfirm
                                                    cancelText="No"
                                                    disabled={
                                                        subscriptionDefaultPaymentMethodUpdating ||
                                                        subscription.default_payment_method ===
                                                            paymentMethod.id
                                                            ? true
                                                            : false
                                                    }
                                                    key="set-default-payment-method"
                                                    okText="Yes"
                                                    title="Set as default payment method?"
                                                    onConfirm={() =>
                                                        updateSubscriptionDefaultPaymentMethod(
                                                            paymentMethod.id
                                                        )
                                                    }
                                                >
                                                    <Button
                                                        disabled={
                                                            subscriptionDefaultPaymentMethodUpdating
                                                        }
                                                        hidden={
                                                            subscription.default_payment_method ===
                                                            paymentMethod.id
                                                                ? true
                                                                : false
                                                        }
                                                        size="small"
                                                        type="text"
                                                    >
                                                        <small>
                                                            Set default
                                                        </small>
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        )}
                                    </List.Item>
                                );
                            }
                        }}
                        rowKey={(item) => item.id}
                    />
                </Card>

                <Modal
                    bodyStyle={{ borderRadius: "10px" }}
                    destroyOnClose={true}
                    footer={null}
                    title="Subscribe"
                    visible={subscribeFormModalVisible}
                    onCancel={() => setSubscribeFormModalVisible(false)}
                >
                    {product && paymentMethods && (
                        <StripeSubscribeForm
                            paymentMethods={paymentMethods}
                            product={product}
                            onCancel={() => setSubscribeFormModalVisible(false)}
                            onError={(e) => console.error(e)}
                            onSuccess={async (_subscription) => {
                                setSubscription(_subscription);
                                message.success("Subscription created");
                                await sleep(0.25);
                                setSubscribeFormModalVisible(false);
                            }}
                        />
                    )}
                </Modal>

                <Modal
                    bodyStyle={{ borderRadius: "10px" }}
                    footer={null}
                    title="Add Payment Method"
                    visible={paymentMethodFormVisible}
                    onCancel={() => setPaymentMethodFormVisible(false)}
                >
                    <StripePaymentMethodForm
                        onCancel={() => setPaymentMethodFormVisible(false)}
                        onError={(e) => console.error(e)}
                        onSuccess={async (paymentMethod) => {
                            setPaymentMethods(
                                paymentMethods
                                    ? [...paymentMethods, paymentMethod]
                                    : [paymentMethod]
                            );
                            message.success("Payment method added");
                            await sleep(0.25);
                            setPaymentMethodFormVisible(false);
                        }}
                    />
                </Modal>
            </div>
        </Fragment>
    );

    async function getProduct() {
        if (!productFetching) {
            setProductFetching(true);
            try {
                const response = await fetch(
                    `${config.TWENTY4TANCO_API_BASE_URL}/stripe/product`,
                    {
                        method: "GET",
                        headers: {
                            Accept: "application/json",
                        },
                        credentials: "include",
                    }
                );

                const result: HttpResult<StripeProductWithPrice> =
                    await response.json();
                if (result.status === "success" && result.data)
                    setProduct(result.data);
                else
                    setProductError(result.message || "Failed to get product.");
            } catch (e) {
                console.error(e);
                setProductError("Failed to get product.");
            }
            setProductFetching(false);
        }
    }

    async function getSubscription() {
        if (!subscriptionFetching) {
            setSubscriptionFetching(true);
            try {
                const response = await fetch(
                    `${config.TWENTY4TANCO_API_BASE_URL}/stripe/subscription`,
                    {
                        method: "GET",
                        headers: {
                            Accept: "application/json",
                        },
                        credentials: "include",
                    }
                );

                const result: HttpResult<StripeSubscription> =
                    await response.json();
                if (result.status === "success") setSubscription(result.data);
            } catch (e) {
                console.error(e);
            }
            setSubscriptionFetching(false);
        }
    }

    async function getPaymentMethods() {
        if (!paymentMethodsFetching) {
            setPaymentMethodsFetching(true);
            try {
                const response = await fetch(
                    `${config.TWENTY4TANCO_API_BASE_URL}/stripe/payment-methods`,
                    {
                        method: "GET",
                        headers: {
                            Accept: "application/json",
                        },
                        credentials: "include",
                    }
                );

                const result: HttpResult<PaymentMethod[]> =
                    await response.json();
                if (result.status === "success") setPaymentMethods(result.data);
                else
                    setPaymentMethodsError(
                        result.message || "Failed to get payment methods."
                    );
            } catch (e) {
                console.error(e);
                setPaymentMethodsError("Failed to get payment methods.");
            }
            setPaymentMethodsFetching(false);
        }
    }

    async function updateSubscriptionDefaultPaymentMethod(
        paymentMethodId: string
    ) {
        if (subscription && !subscriptionDefaultPaymentMethodUpdating) {
            setSubscriptionDefaultPaymentMethodUpdating(true);
            await sleep(1);
            try {
                const response = await fetch(
                    `${config.TWENTY4TANCO_API_BASE_URL}/stripe/subscription/default-payment-method`,
                    {
                        method: "PUT",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            paymentMethodId,
                        }),
                        credentials: "include",
                    }
                );

                const result: HttpResult<StripeSubscription> =
                    await response.json();
                if (result.status === "success" && result.data) {
                    setSubscriptionDefaultPaymentMethodUpdating(false);
                    setSubscription(result.data);
                } else {
                }
            } catch (e) {
                console.error(e);
            }
            setSubscriptionDefaultPaymentMethodUpdating(false);
        }
    }

    async function deleteSubscription(id: string) {
        if (subscription && !subscriptionDeleting) {
            setSubscriptionDeleting(true);
            await sleep(1);
            try {
                const response = await fetch(
                    `${config.TWENTY4TANCO_API_BASE_URL}/stripe/subscription`,
                    {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            id,
                        }),
                        credentials: "include",
                    }
                );

                const result: HttpResult = await response.json();
                if (result.status === "success") setSubscription(null);
                else {
                }
            } catch (e) {
                console.error(e);
            }
            setSubscriptionDeleting(false);
        }
    }

    async function deletePaymentMethod(id: string) {
        if (paymentMethods && !paymentMethodDeletingId) {
            setPaymentMethodDeletingId(id);
            await sleep(1);
            try {
                const response = await fetch(
                    `${config.TWENTY4TANCO_API_BASE_URL}/stripe/payment-method`,
                    {
                        method: "DELETE",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            id,
                        }),
                        credentials: "include",
                    }
                );

                const result: HttpResult = await response.json();
                if (result.status === "success")
                    setPaymentMethods(
                        paymentMethods.filter((o) => o.id !== id)
                    );
            } catch (e) {
                console.error(e);
            }
            setPaymentMethodDeletingId(undefined);
        }
    }
}

export default MembershipPage;
