import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import Button from 'antd/lib/button';
import List from 'antd/lib/list';
import Popconfirm from 'antd/lib/popconfirm';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import moment from 'moment';
import React, {
    ReactNode,
    useState
} from 'react';
import useWindowSize from '~hooks/useWindowSize';
import { NotificationListProps } from './NotificationList.types';
import Styles from './NotificationList.module.less';
import config from '~config';
import Notification from '~types/Notification';
import sleep from '~utilities/sleep';

function NotificationList(props: NotificationListProps): JSX.Element {
    const {
        notifications,
        loading,
        minimal,
        emptyText,
        eachExtra,
        onUpdate,
        onDelete
    } = props;

    const [windowWidth] = useWindowSize();

    const [updatingId, setUpdatingId] = useState<string>();
    const [deletingId, setDeletingId] = useState<string>();

    const itemLayout: 'horizontal' | 'vertical' = minimal ? 'horizontal' : windowWidth <= 768 ? 'horizontal' : 'vertical';

    return (
        <List
            dataSource={notifications}
            loading={loading}
            locale={{
                emptyText
            }}
            itemLayout={itemLayout}
            pagination={!minimal ? {
                size: 'small'
            } : undefined}
            renderItem={notification => {
                const actionsDisabled = (updatingId !== undefined && updatingId !== notification.id) || deletingId !== undefined;

                const actions: ReactNode[] = [];
                if (!minimal && onDelete) actions.push((
                    <Popconfirm
                        cancelText="No"
                        className="br-5"
                        key="delete"
                        okText="Yes"
                        okType="danger"
                        title="Delete this notification?"
                        onConfirm={() => softDelete(notification.id)}
                    >
                        <Button
                            className="br-5 font-size-xs"
                            danger={true}
                            disabled={actionsDisabled}
                            icon={<DeleteOutlined />}
                            type="text"
                        >
                            Delete
                        </Button>
                    </Popconfirm>
                ));
                if (!minimal && onUpdate) actions.push((
                    <Button
                        className="br-5 font-size-xs"
                        disabled={actionsDisabled}
                        icon={notification.readAt
                            ? <CloseCircleOutlined className="c-warning" />
                            : <CheckCircleOutlined className="c-success" />}
                        key="mark-as-read"
                        loading={updatingId === notification.id}
                        type="text"
                        onClick={async () => markAsReadOrUnread(notification.id)}
                    >
                        Mark as {notification.readAt ? 'unread' : 'read'}
                    </Button>
                ));

                return (
                    <List.Item
                        key={notification.id}
                        actions={actions}
                        className={Styles.notification}
                        extra={eachExtra ? eachExtra(notification) : undefined}
                        data-read={notification.readAt !== null ? 'true' : 'false'}
                    >
                        <List.Item.Meta
                            className={Styles.notificationContent}
                            title={notification.title}
                            description={itemLayout === 'horizontal' ? notification.message : moment(notification.metadata.createdAt).fromNow()}
                        />

                        {itemLayout === 'vertical' && (
                            <div className={Styles.notificationContent}>
                                {notification.message}
                            </div>
                        )}
                    </List.Item>
                )
            }}
        />
    );

    async function markAsReadOrUnread(id: string): Promise<void> {
        if (onUpdate && !updatingId) {
            setUpdatingId(id);
            await sleep(1);
            try {
                const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/notifications/${id}/read-or-unread`, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                const result: HttpResult<Notification> = await response.json();
                if (result.status === 'success' && result.data) onUpdate(result.data);
            } catch (e) {
                console.error(e);
            }
            setUpdatingId(undefined);
        }
    }

    async function softDelete(id: string) {
        if (onDelete && !deletingId) {
            setDeletingId(id);
            await sleep(1);
            try {
                const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/notifications/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                const result: HttpResult = await response.json();
                if (result.status === 'success') onDelete(id);
            } catch (e) {
                console.error(e);
            }
            setDeletingId(undefined);
        }
    }
}

export default NotificationList;
