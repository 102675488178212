import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import Button from 'antd/lib/button';
import List from 'antd/lib/list';
import Popconfirm from 'antd/lib/popconfirm';
import Popover from 'antd/lib/popover';
import Typography from 'antd/lib/typography';
import moment from 'moment';
import React, {
    useState
} from 'react';
import config from '~config';
import ContactSubmission from '~types/ContactSubmission';
import {
    ContactSubmissionListProps
} from './ContactSubmissionList.types';
import Style from './ContactSubmissionList.module.less';
import sleep from '~utilities/sleep';

function ContactSubmissionList(props: ContactSubmissionListProps): JSX.Element {
    const {
        contactSubmissions,
        loading,
        onUpdate,
        onDelete
    } = props;

    const [resolvingContactSubmissionId, setResolvingContactSubmissionId] = useState<string>();
    const [deletingContactSubmissionId, setDeletingContactSubmissionId] = useState<string>();

    const contactSubmissionsSorted = contactSubmissions?.sort((a, b) => {
        if (a.resolved && b.resolved) return moment.utc(b.metadata.createdAt).unix() - moment.utc(a.metadata.createdAt).unix();
        else if (a.resolved) return 1;
        else if (b.resolved) return -1;
        else return 0;
    });

    return (
        <List
            dataSource={contactSubmissionsSorted}
            itemLayout="vertical"
            loading={loading}
            locale={{
                emptyText: 'No contact submissions'
            }}
            renderItem={contactSubmission => {
                return (
                    <List.Item
                        actions={[
                            <Popconfirm
                                className="br-5"
                                key="delete"
                                title="Delete this submission?"
                                onConfirm={() => deleteContactSubmission(contactSubmission.id)}
                            >
                                <Button
                                    className="br-5"
                                    disabled={(deletingContactSubmissionId !== undefined &&
                                        deletingContactSubmissionId !== contactSubmission.id
                                    ) || resolvingContactSubmissionId !== undefined}
                                    icon={<DeleteOutlined className="c-error" />}
                                    type="text"
                                >
                                    Delete
                                </Button>
                            </Popconfirm>,
                            <Button
                                className="br-5"
                                disabled={(resolvingContactSubmissionId !== undefined &&
                                    resolvingContactSubmissionId !== contactSubmission.id
                                ) || deletingContactSubmissionId !== undefined}
                                icon={<CheckCircleOutlined className={`${contactSubmission.resolved ? 'c-warning' : 'c-success'}`} />}
                                loading={resolvingContactSubmissionId === contactSubmission.id}
                                key="resolve"
                                type="text"
                                onClick={() => resolveOrUnresolveContactSubmission(contactSubmission.id)}
                            >
                                {contactSubmission.resolved ? 'Unresolve' : 'Resolve'}
                            </Button>
                        ]}
                        extra={(
                            <Popover
                                content={contactSubmission.geo ? (
                                    <div className="mb-0">
                                        <Typography.Paragraph className="mb-0 font-size-xs font-weight-400">
                                            {contactSubmission.geo.timezone}
                                        </Typography.Paragraph>
                                        <Typography.Paragraph className="mb-0 font-size-xs font-weight-300">
                                            {[
                                                contactSubmission.geo.city,
                                                contactSubmission.geo.region,
                                                contactSubmission.geo.country
                                            ].filter(o => o).join(', ')}
                                        </Typography.Paragraph>
                                    </div>
                                ) : (
                                    <Typography.Text className="font-size-xs" type="secondary">
                                        Failed to load geolocation data
                                    </Typography.Text>
                                )}
                                trigger="click"
                            >
                                <Button
                                    className="font-size-xs"
                                    icon={<InfoCircleOutlined />}
                                    size="small"
                                    type="text"
                                >
                                    {contactSubmission.ipAddress}
                                </Button>
                            </Popover>
                        )}
                        key={contactSubmission.id}
                    >
                        <List.Item.Meta
                            className={Style.listItemMeta}
                            title={(
                                <Typography.Link
                                    className="font-size-sm font-weight-300"
                                    href={`mailto:${contactSubmission.emailAddress}`}
                                    target="_blank"
                                >
                                    {contactSubmission.emailAddress}
                                </Typography.Link>
                            )}
                            description={(
                                <Typography.Paragraph className="mb-0 font-size-xs" type="secondary">
                                    {moment.utc(contactSubmission.metadata.createdAt).toDate().toLocaleString()}
                                </Typography.Paragraph>
                            )}
                            style={{
                                opacity: contactSubmission.resolved ? 0.25 : undefined
                            }}
                        />

                        <div
                            style={{
                                opacity: contactSubmission.resolved ? 0.25 : undefined
                            }}
                        >
                            {contactSubmission.message}
                        </div>
                    </List.Item>
                );
            }}
            pagination={{
                pageSize: 5,
            }}
        />
    );

    async function resolveOrUnresolveContactSubmission(id: string) {
        if (!resolvingContactSubmissionId) {
            setResolvingContactSubmissionId(id);
            await sleep(1);
            try {
                const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/admin/contact-submissions/${id}/resolve-or-unresolve`, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                const result: HttpResult<ContactSubmission> = await response.json();
                if (result.status === 'success' && result.data) onUpdate(result.data);
            } catch (e) {
                console.error(e);
            }
            setResolvingContactSubmissionId(undefined);
        }
    }

    async function deleteContactSubmission(id: string) {
        if (!deletingContactSubmissionId) {
            setDeletingContactSubmissionId(id);
            await sleep(1);
            try {
                const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/admin/contact-submissions/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                const result: HttpResult = await response.json();
                if (result.status === 'success') onDelete(id);
            } catch (e) {
                console.error(e);
            }
            setDeletingContactSubmissionId(undefined);
        }
    }
}

export default ContactSubmissionList;
