import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import message from 'antd/lib/message';
import PageHeader from 'antd/lib/page-header';
import React, {
    Fragment,
    useEffect,
    useState
} from 'react';
import Helmet from 'react-helmet';
import {
    Link,
    useNavigate,
    useSearchParams
} from 'react-router-dom';
import config from '~config';
import Style from './ResetPasswordPage.module.less';

function ResetPasswordPage(): JSX.Element {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    const code = searchParams.get('code')

    const [resetPasswordForm] = Form.useForm();
    const [resetPasswordFormSubmitting, setResetPasswordFormSubmitting] = useState<boolean>(false);
    const [resetPasswordFormError, setResetPasswordFormError] = useState<string>();

    const [resetPasswordCodeForm] = Form.useForm();
    const [resetPasswordCodeFormSubmitting, setResetPasswordCodeFormSubmitting] = useState<boolean>(false);
    const [resetPasswordCodeFormError, setResetPasswordCodeFormError] = useState<string>();

    const [enterResetCode, setEnterResetCode] = useState<boolean>(false);

    useEffect(() => {
        if (code != null) {
            console.log(code)
            resetPasswordCodeForm.setFieldsValue({
                code
            })
            setEnterResetCode(true)
        }
    }, [code])

    return (
        <Fragment>
            <Helmet>
                <title>Reset Password - Twenty4 Tan Co.</title>
            </Helmet>

            <div className="container-xs my-50 px-50 flex flex-align-center flex-justify-center">
                <div className="w-100">
                    <Card
                        actions={[
                            <Button
                                className="br-10"
                                key="enter-reset-code-manually"
                                type="link"
                                onClick={() => setEnterResetCode(!enterResetCode)}
                            >
                                {enterResetCode ? 'Reset your password' : 'Enter a reset code'}
                            </Button>
                        ]}
                        className={`${Style.resetPasswordCard} w-100 br-10 b-light-1 bs-grey-5`}
                        extra={(
                            <Link to="/login">
                                Back to sign in
                            </Link>
                        )}
                        title={(
                            <PageHeader
                                className="p-0"
                                title="Reset password"
                            />
                        )}
                    >
                        <Form
                            colon={false}
                            form={resetPasswordForm}
                            hidden={enterResetCode === true}
                            requiredMark={false}
                            validateTrigger="onSubmit"
                            onFinish={async values => {
                                if (!resetPasswordFormSubmitting) {
                                    setResetPasswordFormError(undefined);
                                    setResetPasswordFormSubmitting(true);
                                    try {
                                        const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/reset-password`, {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            credentials: 'include',
                                            body: JSON.stringify(values)
                                        });

                                        const result: HttpResult = await response.json();
                                        if (result.status === 'success') {
                                            message.success('Password reset email sent');
                                        } else {
                                            const error = result.message || 'Failed to request password reset';
                                            message.error(error);
                                            setResetPasswordFormError(error);
                                        }
                                    } catch (e) {
                                        console.error(e);
                                        message.error('Failed to request password reset');
                                        setResetPasswordFormError('Failed to request password reset');
                                    }
                                    setResetPasswordFormSubmitting(false);
                                }
                            }}
                        >
                            <Form.Item
                                name="emailAddress"
                                required={true}
                                rules={[
                                    { message: 'Email address is required.', required: true }
                                ]}
                            >
                                <Input
                                    autoComplete="username"
                                    className="br-5"
                                    placeholder="Email address"
                                    type="email"
                                />
                            </Form.Item>
                            <Form.Item
                                className="mb-0"
                            >
                                <Button
                                    block={true}
                                    className="br-5"
                                    htmlType="submit"
                                    loading={resetPasswordFormSubmitting}
                                    type="primary"
                                >
                                    Request password reset
                                </Button>
                            </Form.Item>
                        </Form>
                        <Form
                            colon={false}
                            form={resetPasswordCodeForm}
                            hidden={enterResetCode === false}
                            requiredMark={false}
                            validateTrigger="onSubmit"
                            onFinish={async values => {
                                if (!resetPasswordCodeFormSubmitting) {
                                    setResetPasswordCodeFormError(undefined);
                                    setResetPasswordCodeFormSubmitting(true);
                                    try {
                                        const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/reset-password`, {
                                            method: 'PUT',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            credentials: 'include',
                                            body: JSON.stringify({
                                                resetCode: values.code,
                                                password: values.password,
                                                confirmPassword: values.confirmPassword
                                            })
                                        });

                                        const result: HttpResult = await response.json();
                                        if (result.status === 'success') {
                                            message.success('Password updated');
                                            navigate('/login');
                                        } else {
                                            const error = result.message || 'Failed to reset password';
                                            message.error(error);
                                            setResetPasswordCodeFormError(error);
                                        }
                                    } catch (e) {
                                        console.error(e);
                                        message.error('Failed to reset password');
                                        setResetPasswordCodeFormError('Failed to reset password');
                                    }
                                    setResetPasswordCodeFormSubmitting(false);
                                }
                            }}
                        >
                            <Form.Item
                                name="code"
                                hidden={code != null}
                                required={true}
                                rules={[
                                    { message: 'Code is required.', required: true }
                                ]}
                            >
                                <Input
                                    autoComplete="off"
                                    className="br-5"
                                    placeholder="Reset code"
                                />
                            </Form.Item>
                            <Form.Item
                                hasFeedback={true}
                                name="password"
                                required={true}
                                rules={[
                                    { message: 'Password is required.', required: true },
                                    { message: 'Password must be at least 8 characters long.', min: 8 },
                                    { validator: (_, value: string) => value && value.split('').some(o => o === o.toUpperCase()) ? Promise.resolve() : Promise.reject('Password must contain at least 1 uppercase letter.') },
                                    { validator: (_, value: string) => value && value.split('').some(o => /^-?\d+$/.test(o)) ? Promise.resolve() : Promise.reject('Password must contain at least 1 number.') }
                                ]}
                            >
                                <Input.Password
                                    autoComplete="new-password"
                                    className="br-5"
                                    placeholder="New password"
                                />
                            </Form.Item>
                            <Form.Item
                                dependencies={['password']}
                                hasFeedback={true}
                                name="confirmPassword"
                                required={true}
                                rules={[
                                    { message: 'Passwords do not match.', validator: (_, value: string) => value === resetPasswordCodeForm.getFieldValue('password') ? Promise.resolve() : Promise.reject() }
                                ]}
                            >
                                <Input.Password
                                    autoComplete="new-password"
                                    className="br-5"
                                    placeholder="Confirm new password"
                                />
                            </Form.Item>
                            <Form.Item
                                className="mb-0"
                            >
                                <Button
                                    block={true}
                                    className="br-5"
                                    htmlType="submit"
                                    loading={resetPasswordCodeFormSubmitting}
                                    type="primary"
                                >
                                    Reset password
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
        </Fragment>
    );
}

export default ResetPasswordPage;
