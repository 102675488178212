import CreditCardOutlined from '@ant-design/icons/CreditCardFilled';
import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Empty from 'antd/lib/empty';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Select from 'antd/lib/select';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import React, {
    useContext,
    useState
} from 'react';
import config from '~config';
import Locations from '~context/Locations';
import StripeSubscription from '~types/StripeSubscription';
import formatCardBrand from '~utilities/formatCardBrand';
import formatCardExpiry from '~utilities/formatCardExpiry';
import sleep from '~utilities/sleep';
import {
    StripeSubscribeFormProps,
    StripeSubscribeFormValues
} from './StripeSubscribeForm.types';

function StripeSubscribeForm(props: StripeSubscribeFormProps): JSX.Element {
    const {
        initialValues,
        product,
        paymentMethods,
        onCancel,
        onError,
        onSuccess
    } = props;

    const locations = useContext(Locations)

    const [form] = Form.useForm<StripeSubscribeFormValues>();
    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<StripeSubscribeFormValues>({
        paymentMethodId: paymentMethods.length >= 1 ? paymentMethods[0].id : undefined,
        ...initialValues
    });

    const [useCoupon, setUseCoupon] = useState<boolean>(false);

    return (
        <Form
            colon={false}
            form={form}
            initialValues={formValues}
            labelAlign="left"
            labelCol={{ xs: 24, sm: 24, md: 8 }}
            requiredMark={false}
            wrapperCol={{ xs: 24, sm: 24, md: 16 }}
            onFinish={async values => {
                if (!formSubmitting) {
                    setFormSubmitting(true);
                    await sleep(2);
                    try {
                        const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/stripe/subscription`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(values),
                            credentials: 'include'
                        });

                        const result: HttpResult<StripeSubscription> = await response.json();
                        if (result.status === 'success' && result.data) {
                            if (onSuccess) onSuccess(result.data);
                        } else {
                            if (onError) onError(result.message || 'Failed to create subscription.');
                        }
                    } catch (e) {
                        console.error(e);
                        if (onError) onError('Failed to create subscription.');
                    }
                    setFormSubmitting(false);
                }
            }}
            onValuesChange={(_, values) => setFormValues(values)}
        >
            <Typography.Title
                className="font-weight-400"
                level={5}
            >
                {product.name}
            </Typography.Title>
            <Typography.Paragraph>
                {product.price.nickname} - ${(product.price.unit_amount / 100).toFixed(2)} / {product.price.recurring.interval_count > 1 ? `${product.price.recurring.interval_count} ` : ''}{product.price.recurring.interval}{product.price.recurring.interval_count > 1 ? 's' : ''}
            </Typography.Paragraph>
            <Form.Item
                label="Payment Method"
                name="paymentMethodId"
                required={true}
                rules={[
                    { message: 'Payment method is required.', required: true }
                ]}
            >
                <Select
                    className="w-100 br-5"
                    dropdownClassName="br-5"
                    notFoundContent={(
                        <Empty
                            description="Please add a payment method"
                            image={<CreditCardOutlined style={{ fontSize: '5em' }} />}
                            imageStyle={{ height: 'unset' }}
                        />
                    )}
                    placeholder="Select a payment method"
                >
                    {paymentMethods.map(paymentMethod => {
                        if (!paymentMethod.card) return;

                        const { icon, name } = formatCardBrand(paymentMethod.card.brand);
                        const expiry = formatCardExpiry(paymentMethod.card.exp_month, paymentMethod.card.exp_year);

                        return (
                            <Select.Option
                                key={paymentMethod.id}
                                value={paymentMethod.id}
                            >
                                <Space>
                                    {icon}
                                    <Typography.Text>
                                        {name}
                                    </Typography.Text>
                                    <Typography.Text type="secondary">
                                        {paymentMethod.card.last4} - {expiry}
                                    </Typography.Text>
                                </Space>
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                label="Home Location"
                name="homeLocation"
                required={true}
                rules={[
                    { message: 'Home location is required.', required: true }
                ]}
            >
                <Select
                    className="w-100 br-5"
                    dropdownClassName="br-5"
                    placeholder="Select a home location"
                >
                    {locations.data?.map(location => (
                        <Select.Option key={location.id} value={location.id}>
                            {location.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                hidden={!useCoupon}
                wrapperCol={{ md: { offset: 8 } }}
            >
                <Row
                    align="middle"
                    gutter={10}
                    wrap={false}
                >
                    <Col flex="auto">
                        <Form.Item
                            className="mb-0"
                            name="coupon"
                        >
                            <Input
                                className="br-5"
                                placeholder="Coupon code"
                                readOnly={formSubmitting}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Button
                            size="small"
                            type="link"
                            onClick={() => {
                                form.setFieldsValue({ coupon: '' });
                                setFormValues({ ...formValues, coupon: '' });
                                setUseCoupon(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
            {!useCoupon && (
                <div className="mb-25 text-right" style={{
                    position: 'relative',
                    top: '-3px',
                    paddingTop: '7px'
                }}>
                    <Button
                        size="small"
                        type="link"
                        onClick={() => setUseCoupon(true)}
                    >
                        Apply coupon
                    </Button>
                </div>
            )}
            <Typography.Paragraph
                className="mb-0 font-size-xs"
                type="secondary"
            >
                You can cancel your membership at any time. By clicking &quot;Subscribe&quot; you confirm that
            </Typography.Paragraph>
            <ol
                className="font-size-xs"
                style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                type="a"
            >
                <li>you are not a minor (you are legally above the minor age limit in your state or territory)</li>
                <li>or, you are the parent or legal guardian of a minor that will be using our services</li>
            </ol>
            <Form.Item
                className="mb-0 text-right"
                wrapperCol={{ span: 24 }}
            >
                <Space>
                    <Button
                        className="br-5"
                        disabled={formSubmitting}
                        htmlType="reset"
                        onClick={() => {
                            if (onCancel) onCancel();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="br-5"
                        htmlType="submit"
                        loading={formSubmitting}
                        type="primary"
                    >
                        Subscribe
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

export default StripeSubscribeForm;
