import Typography from 'antd/lib/typography';
import React, {
    Fragment,
    useContext,
    useEffect
} from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import LayoutContext from '~context/Layout';

function HowItWorksPage(): JSX.Element {
    const layout = useContext(LayoutContext);

    useEffect(() => {
        if (layout.data && layout.actions) {
            if (layout.data.header !== true) layout.actions.updateHeader(true);
            if (layout.data.footer !== true) layout.actions.updateFooter(true);
        }
    }, [layout.data, layout.actions]);

    return (
        <Fragment>
            <Helmet>
                <title>How it Works - Twenty4 Tan Co.</title>
            </Helmet>

            <div className="container-md my-50 px-50 text-center-md">
                <Typography.Title className="font-weight-400" level={2}>
                    How does it work?
                </Typography.Title>
                <Typography.Paragraph className="mb-0">
                    Below are the exact steps you can take to get tanning right away!
                </Typography.Paragraph>
                <Typography.Paragraph>
                    <ol>
                        <li>You&apos;re already on our website, so step 1 is done!</li>
                        <li>Click the &apos;Sign In&apos; button in the top right of the page.</li>
                        <li>Click &apos;Create an Account&apos; below the login area.</li>
                        <li>Complete the fields: First Name, Last Name, Date of Birth, email address, and creating a password. If you are under 18 years old, you will have to have a parent or guardian sign up for an account for you.</li>
                        <li>Click and read the &apos;Terms of Service&apos;.</li>
                        <li>Click the two boxes to agree to the terms and to receiving communications from us.</li>
                        <li>Click &apos;Register&apos;.</li>
                        <li>You will receive an email from us to verify your email. It may take a few minutes to arrive in your inbox. Be sure to check your Spam folder if you don&apos;t receive it!</li>
                        <li>Verify your email through the email that you received.</li>
                        <li>Come back to the website and click &apos;Sign In&apos; in the top right of the page.</li>
                        <li>Sign in using the email and password that you just created.</li>
                        <li>You&apos;re now logged in. Click on your email that appears in the top right of the page to reveal the drop-down menu.</li>
                        <li>From the drop-down menu, click on &apos;Membership&apos;.</li>
                        <li>You will add a payment method (credit or debit card). Click &apos;Add+&apos; next to Payment Methods.</li>
                        <li>Now you can click &apos;Subscribe&apos; just above the payment methods.</li>
                        <li>A window will pop up and you&apos;ll click &apos;Subscribe&apos; again.</li>
                        <li>Click back on your email in the top right of the page to reveal the drop-down menu.</li>
                        <li>Click &apos;Access Code&apos;.</li>
                        <li>Here you will see your 4-digit access code. Use this access code when you get to the tanning salon. Use the keypad to the right of the entrance door to enter your access code and then press the red button. The door will unlock in about 2 seconds.</li>
                        <li>Each tanning room has instructions for how to use the beds.</li>
                    </ol>
                </Typography.Paragraph>
            </div>
        </Fragment>
    )
}

export default HowItWorksPage
