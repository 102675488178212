import Typography from 'antd/lib/typography';
import React, {
    Fragment,
    useContext,
    useEffect
} from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import LayoutContext from '~context/Layout';

function AboutPage(): JSX.Element {
    const layout = useContext(LayoutContext);

    useEffect(() => {
        if (layout.data && layout.actions) {
            if (layout.data.header !== true) layout.actions.updateHeader(true);
            if (layout.data.footer !== true) layout.actions.updateFooter(true);
        }
    }, [layout.actions, layout.data]);

    return (
        <Fragment>
            <Helmet>
                <title>About Us - Twenty4 Tan Co.</title>
            </Helmet>

            <div className="container-md my-50 px-50">
                <Typography.Title
                    className="font-weight-400"
                    level={2}
                >
                    About us
                </Typography.Title>
                <Typography.Paragraph>
                    We started as Sun24 in 2006.
                    Thanks to our members, we have been able to provide you with a beautiful remodeled and expanded salon.
                    We now have multiple types of tanning beds to choose from!
                </Typography.Paragraph>
                <Typography.Paragraph>
                    You will still enjoy the same ease of use as before, but with a more streamlined membership process.
                    Your membership is now in your hands!
                    You can change, cancel, and restart whenever you want directly from your member account on our website.
                    No sign-up appointment needed, <Link to="/membership">get started today</Link>!
                </Typography.Paragraph>
            </div>
        </Fragment>
    );
}

export default AboutPage;
