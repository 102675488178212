import Alert from 'antd/lib/alert';
import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import Col from 'antd/lib/col';
import PageHeader from 'antd/lib/page-header';
import Row from 'antd/lib/row';
import Select from 'antd/lib/select';
import Space from 'antd/lib/space';
import Statistic from 'antd/lib/statistic';
import Tabs from 'antd/lib/tabs';
import Typography from 'antd/lib/typography';
import React, {
    Fragment,
    useContext,
    useEffect,
    useState
} from 'react';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import ContactSubmissionList from '~components/ContactSubmissionList';
import UserTable from '~components/UserTable';
import config from '~config';
import LayoutContext from '~context/Layout';
import UserContext from '~context/User';
import ContactSubmission from '~types/ContactSubmission';
import SendGridStats from '~types/SendGridStats';
import User from '~types/User';
import sleep from '~utilities/sleep';

type Overview = {
    totalOrphanedRegistrations: number;
    totalUsers: number;
    totalUnresolvedContactSubmissions: number;
}

function AdministrationPage() {
    const navigate = useNavigate();
    const layout = useContext(LayoutContext);
    const user = useContext(UserContext);

    const [tabsActiveKey, setTabsActiveKey] = useState<string>(localStorage.getItem('administration-tabs-active-key') || 'overview');

    const [overview, setOverview] = useState<Overview>();
    const [overviewFetching, setOverviewFetching] = useState<boolean>(false);
    const [overviewError, setOverviewError] = useState<string>();

    const [users, setUsers] = useState<User[]>();
    const [usersFetching, setUsersFetching] = useState<boolean>(false);

    const [contactSubmissions, setContactSubmissions] = useState<ContactSubmission[]>();
    const [contactSubmissionsFetching, setContactSubmissionsFetching] = useState<boolean>(false);

    const [devices, setDevices] = useState<any>()
    const [devicesFetching, setDevicesFetching] = useState<boolean>(false)

    const [sendGridStats, setSendGridStats] = useState<SendGridStats>();
    const [sendGridStatsFetching, setSendGridStatsFetching] = useState<boolean>(false);

    useEffect(() => {
        if (layout.data && layout.actions) {
            if (layout.data.header !== true) layout.actions.updateHeader(true);
            if (layout.data.footer !== true) layout.actions.updateFooter(true);
        }
    }, [layout.actions, layout.data]);

    useEffect(() => {
        if (user.data) {
            if (!user.data.administrator) navigate('/');
            else {
                getOverview();
                getUsers();
                getContactSubmissions();
                getDevices();
                getSendGridStats();
            }
        }
    }, [user.data]);

    useEffect(() => {
        localStorage.setItem('administration-tabs-active-key', tabsActiveKey);
    }, [tabsActiveKey]);

    return (
        <Fragment>
            <Helmet>
                <title>Administration - Twenty4 Tan Co.</title>
            </Helmet>

            <div className="container-xl my-50 px-50">
                {localStorage.getItem('administration-alert') === null && (
                    <Alert
                        className="mb-50 br-10"
                        closable={true}
                        closeText="Dismiss"
                        description="Only you and any other administrators can view this page."
                        message="Restricted page"
                        type="warning"
                        onClose={() => localStorage.setItem('administration-alert', '')}
                    />
                )}
                <Card
                    className="br-10 bs-grey-5"
                    loading={overviewFetching}
                    title={(
                        <PageHeader
                            className="p-0"
                            title="Administration"
                        />
                    )}
                >
                    <Tabs
                        activeKey={tabsActiveKey}
                        onChange={activeKey => setTabsActiveKey(activeKey)}
                    >
                        <Tabs.TabPane
                            key="overview"
                            tab="Overview"
                        >
                            <Row
                                align="stretch"
                                gutter={15}
                                style={{
                                    marginTop: '-10px',
                                    marginBottom: '-10px'
                                }}
                            >
                                <Col
                                    className="my-10"
                                    style={{ height: 'inherit !important' }}
                                    xs={24} sm={24} md={12} lg={8}
                                >
                                    <Card
                                        className="h-100 br-10"
                                    >
                                        <Statistic
                                            loading={overviewFetching}
                                            title="Total orphaned registrations"
                                            value={overview?.totalOrphanedRegistrations}
                                            valueRender={node => (
                                                <span className={overview?.totalOrphanedRegistrations === 0 ? 'c-success' : 'c-warning'}>
                                                    {node}
                                                </span>
                                            )}
                                        />
                                        <Typography.Paragraph className="mb-0 font-size-xs" type="secondary">
                                            Number of registrations that have not been verified.
                                        </Typography.Paragraph>
                                    </Card>
                                </Col>
                                <Col
                                    className="my-10"
                                    style={{ height: 'inherit !important' }}
                                    xs={24} sm={24} md={12} lg={8}
                                >
                                    <Card
                                        className="h-100 br-10"
                                    >
                                        <Statistic
                                            loading={overviewFetching}
                                            title="Total users"
                                            value={overview?.totalUsers}
                                            valueRender={node => (
                                                <span className="c-success">
                                                    {node}
                                                </span>
                                            )}
                                        />
                                        <Typography.Paragraph className="mb-0 font-size-xs" type="secondary">
                                            Number of users with an active account on the website.
                                        </Typography.Paragraph>
                                    </Card>
                                </Col>
                                <Col
                                    className="my-10"
                                    style={{ height: 'inherit !important' }}
                                    xs={24} sm={24} md={12} lg={8}
                                >
                                    <Card
                                        className="h-100 br-10"
                                    >
                                        <Statistic
                                            loading={overviewFetching}
                                            title="Unresolved contact submissions"
                                            value={overview?.totalUnresolvedContactSubmissions}
                                            valueRender={node => (
                                                <span className={overview?.totalUnresolvedContactSubmissions === 0 ? 'c-success' : 'c-warning'}>
                                                    {node}
                                                </span>
                                            )}
                                        />
                                        <Typography.Paragraph className="mb-0 font-size-xs" type="secondary">
                                            Number of contact submissions that have not been resolved yet.
                                        </Typography.Paragraph>
                                    </Card>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key="users"
                            tab="Users"
                        >
                            <Card
                                bodyStyle={{
                                    padding: '0'
                                }}
                            >
                                <UserTable
                                    users={users}
                                    loading={usersFetching}
                                />
                            </Card>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key="contact-submissions"
                            tab="Contact Submissions"
                        >
                            <ContactSubmissionList
                                contactSubmissions={contactSubmissions}
                                loading={contactSubmissionsFetching}
                                onUpdate={contactSubmission => {
                                    if (contactSubmissions) {
                                        const otherContactSubmissions = contactSubmissions.filter(o => o.id !== contactSubmission.id);
                                        setContactSubmissions([contactSubmission, ...otherContactSubmissions]);
                                    } else setContactSubmissions([contactSubmission]);
                                    if (overview) {
                                        setOverview({
                                            ...overview,
                                            totalUnresolvedContactSubmissions: overview.totalUnresolvedContactSubmissions + (contactSubmission.resolved ? -1 : 1)
                                        })
                                    }
                                }}
                                onDelete={id => {
                                    if (contactSubmissions) {
                                        const otherContactSubmissions = contactSubmissions.filter(o => o.id !== id);
                                        setContactSubmissions(otherContactSubmissions);
                                    }
                                }}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane key="devices" tab="Devices">
                            <pre>{JSON.stringify(devices, null, 4)}</pre>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="sengrid" tab="SendGrid">
                            <pre>{JSON.stringify(sendGridStats, null, 4)}</pre>
                        </Tabs.TabPane>
                    </Tabs>
                </Card>
            </div>
        </Fragment>
    );

    async function getOverview() {
        if (!overviewFetching) {
            setOverviewFetching(true);
            if (overviewError) setOverviewError(undefined);
            try {
                const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/admin/overview`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                const result: HttpResult<Overview> = await response.json();
                if (result.status === 'success' && result.data) setOverview(result.data);
                else setOverviewError(result.message || 'Failed to get overview');
            } catch (e) {
                console.error(e);
            }
            setOverviewFetching(false);
        }
    }

    async function getUsers() {
        if (!usersFetching) {
            setUsersFetching(true);
            try {
                const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/admin/users`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                const result: HttpResult<User[]> = await response.json();
                if (result.status === 'success' && result.data) setUsers(result.data);
            } catch (e) {
                console.error(e);
            }
            setUsersFetching(false);
        }
    }

    async function getContactSubmissions() {
        if (!contactSubmissionsFetching) {
            setContactSubmissionsFetching(true);
            await sleep(1);
            try {
                const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/admin/contact-submissions`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                const result: HttpResult<ContactSubmission[]> = await response.json();
                if (result.status === 'success' && result.data) setContactSubmissions(result.data);
            } catch (e) {
                console.error(e);
            }
            setContactSubmissionsFetching(false);
        }
    }

    async function getDevices() {
        if (!devicesFetching) {
            setDevicesFetching(true)
            await sleep(1)
            try {
                const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/admin/devices`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                })

                const result: HttpResult<any> = await response.json()
                if (result.status === 'success' && result.data) setDevices(result.data)
            } catch (e) {
                console.error(e)
            }
            setDevicesFetching(false)
        }
    }

    async function getSendGridStats() {
        if (!sendGridStatsFetching) {
            setSendGridStatsFetching(true);
            await sleep(1);
            try {
                const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/admin/sendgrid/stats`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                const result: HttpResult<SendGridStats> = await response.json();
                if (result.status === 'success' && result.data) setSendGridStats(result.data);
            } catch (e) {
                console.error(e);
            }
            setSendGridStatsFetching(false);
        }
    }
}

export default AdministrationPage;
