import BellOutlined from '@ant-design/icons/BellOutlined';
import CreditCardOutlined from '@ant-design/icons/CreditCardOutlined';
import FundViewOutlined from '@ant-design/icons/FundViewOutlined';
import LockOutlined from '@ant-design/icons/LockOutlined';
import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import MenuOutlined from '@ant-design/icons/MenuOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import Badge from 'antd/lib/badge';
import Button from 'antd/lib/button';
import Drawer from 'antd/lib/drawer';
import Image from 'antd/lib/image';
import Menu from 'antd/lib/menu';
import Popover from 'antd/lib/popover';
import Skeleton from 'antd/lib/skeleton';
import moment from 'moment';
import React, {
    Fragment,
    useContext,
    useEffect,
    useState
} from 'react';
import {
    Link,
    useLocation,
    useNavigate
} from 'react-router-dom';
import logo from '~assets/logo.png';
import NotificationList from '~components/NotificationList';
import NotificationsContext from '~context/Notifications';
import UserContext from '~context/User';
import useWindowSize from '~hooks/useWindowSize';
import Style from './Header.module.less';

type NavbarItem<IsSubmenu extends boolean = false> = {
    items: IsSubmenu extends true ? NavbarItem<false>[] : undefined;
    key: string;
    title: string;
    to: IsSubmenu extends true ? undefined : string;
}

const navbarItems: NavbarItem<true | false>[] = [
    {
        items: undefined,
        key: 'home',
        title: 'Home',
        to: '/'
    },
    {
        items: undefined,
        key: 'how-it-works',
        title: 'How it Works',
        to: '/how-it-works'
    },
    {
        items: undefined,
        key: 'locations',
        title: 'Locations',
        to: '/locations'
    },
    {
        items: undefined,
        key: 'about',
        title: 'About',
        to: '/about'
    },
    {
        items: undefined,
        key: 'contact',
        title: 'Contact',
        to: '/contact'
    },
    {
        items: undefined,
        key: 'faq',
        title: 'FAQ',
        to: '/faq'
    }
];

function Header(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const user = useContext(UserContext);
    const notifications = useContext(NotificationsContext);

    const [windowWidth] = useWindowSize();

    const [selectedMenuKeys, setSelectedMenuKeys] = useState<string[]>([]);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [notificationsPopupVisible, setNotificationsPopupVisible] = useState<boolean>(false);

    useEffect(() => {
        let navbarItemKey: string | undefined = undefined;
        if (location.pathname === '/' || location.pathname.startsWith('/?')) navbarItemKey = 'home';
        else if (location.pathname.includes('/locations')) navbarItemKey = 'locations';
        else if (location.pathname.includes('/how-it-works')) navbarItemKey = 'how-it-works';
        else if (location.pathname.includes('/about')) navbarItemKey = 'about';
        else if (location.pathname.includes('/contact')) navbarItemKey = 'contact';
        else if (location.pathname.includes('/faq')) navbarItemKey = 'faq';
        else if (location.pathname.includes('/notifications')) navbarItemKey = 'notifications';
        else if (location.pathname.includes('/administration')) navbarItemKey = 'user:administration';
        else if (location.pathname.includes('/membership')) navbarItemKey = 'user:membership';
        else if (location.pathname.includes('/access-code')) navbarItemKey = 'user:access-code';
        else if (location.pathname.includes('/settings')) navbarItemKey = 'user:settings';
        setSelectedMenuKeys(navbarItemKey ? [navbarItemKey] : []);
    }, [location.pathname]);

    let onAuthPage = false;
    if (location.pathname.includes('/login') ||
        location.pathname.includes('/register') ||
        location.pathname.includes('/reset-password')
    ) onAuthPage = true;

    const onNotificationsPage = location.pathname.includes('/notifications');

    const filteredAndSortedNotifications = notifications.data
        ? notifications.data
            .filter(n => n.readAt === null)
            .sort((a, b) =>
                moment(b.metadata.createdAt).valueOf() -
                moment(a.metadata.createdAt).valueOf())
            .slice(0, 3)
        : undefined;

    return (
        <div className="w-100 bg-white bs-grey-1">
            <Drawer
                bodyStyle={{ padding: '10px 0 0' }}
                headerStyle={{ height: '65px' }}
                placement="left"
                title={(
                    <img
                        height={32}
                        width={46}
                        src={logo}
                    />
                )}
                visible={drawerVisible}
                width={280}
                onClose={() => setDrawerVisible(false)}
            >
                <Menu
                    className={Style.navbar}
                    mode="inline"
                    selectedKeys={selectedMenuKeys}
                >
                    {renderNavbarItems()}
                </Menu>
            </Drawer>

            <div className="container-xxl">
                <Menu
                    className={Style.navbar}
                    mode="horizontal"
                    selectedKeys={selectedMenuKeys}
                >
                    {windowWidth <= 896 ? (
                        <Menu.Item
                            className={`${Style.navbarItem} ${Style.navbarItemBrand}`}
                            key="drawer-toggle"
                        >
                            <Button
                                className="br-5"
                                icon={<MenuOutlined />}
                                onClick={() => setDrawerVisible(true)}
                            />
                        </Menu.Item>
                    ) : (
                        <Fragment>
                            <Menu.Item
                                className={`${Style.navbarItem} ${Style.navbarItemNoHover}`}
                                key="brand"
                            >
                                <Image
                                    height={38}
                                    width={55}
                                    preview={false}
                                    src={logo}
                                />
                            </Menu.Item>
                            {renderNavbarItems()}
                        </Fragment>
                    )}

                    {user.data ? (
                        <Fragment>
                            {!onNotificationsPage && (
                                <Menu.Item
                                    className={`${Style.navbarItem}`}
                                    key="notifications"
                                    style={{ marginLeft: 'auto' }}
                                >
                                    <Popover
                                        content={(
                                            <div>
                                                <NotificationList
                                                    notifications={filteredAndSortedNotifications}
                                                    loading={notifications.data === undefined}
                                                    minimal={true}
                                                    emptyText="No new notifications"
                                                    eachExtra={notification => {
                                                        let color: 'blue' | 'green' | 'yellow' | 'red';
                                                        switch (notification.type) {
                                                            case 'info':
                                                                color = 'blue';
                                                                break;
                                                            case 'success':
                                                                color = 'green';
                                                                break;
                                                            case 'warning':
                                                                color = 'yellow';
                                                                break;
                                                            case 'error':
                                                                color = 'red';
                                                                break;
                                                            default:
                                                                color = 'blue';
                                                        }

                                                        return (
                                                            <Badge
                                                                color={color}
                                                                style={{
                                                                    position: 'relative',
                                                                    top: '-2px',
                                                                    marginLeft: '10px'
                                                                }}
                                                            />
                                                        )
                                                    }}
                                                />
                                                <div className="text-center">
                                                    <Link
                                                        to="/notifications"
                                                        onClick={() => setNotificationsPopupVisible(false)}
                                                    >
                                                        View notifications page
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                        overlayStyle={{ maxWidth: '100vw', width: '300px' }}
                                        trigger="click"
                                        visible={notificationsPopupVisible}
                                        onVisibleChange={visible => setNotificationsPopupVisible(visible)}
                                    >
                                        <Badge
                                            color="volcano"
                                            count={filteredAndSortedNotifications?.length}
                                            offset={[0, 16]}
                                            overflowCount={10}
                                        >
                                            <Button
                                                className="br-5"
                                                icon={<BellOutlined />}
                                                type="text"
                                            />
                                        </Badge>
                                    </Popover>
                                </Menu.Item>
                            )}
                            <Menu.SubMenu
                                className={Style.navbarItem}
                                icon={<UserOutlined />}
                                key="user"
                                style={{ marginLeft: onNotificationsPage ? 'auto' : undefined }}
                                title={user.data.emailAddress}
                            >
                                {user.data.administrator && (
                                    <Fragment>
                                        <Menu.Item
                                            className={Style.navbarItem}
                                            icon={<FundViewOutlined />}
                                            key="user:administration"
                                            onClick={() => navigate('/administration')}
                                        >
                                            Administration
                                        </Menu.Item>
                                        <Menu.Divider />
                                    </Fragment>
                                )}
                                <Menu.Item
                                    className={Style.navbarItem}
                                    icon={<CreditCardOutlined />}
                                    key="user:membership"
                                    onClick={() => navigate('/membership')}
                                >
                                    Membership
                                </Menu.Item>
                                <Menu.Item
                                    className={Style.navbarItem}
                                    icon={<LockOutlined />}
                                    key="user:access-code"
                                    onClick={() => navigate('/access-code')}
                                >
                                    Access Code
                                </Menu.Item>
                                <Menu.Item
                                    className={Style.navbarItem}
                                    icon={<SettingOutlined />}
                                    key="user:settings"
                                    onClick={() => navigate('/settings')}
                                >
                                    Settings
                                </Menu.Item>
                                <Menu.Divider />
                                <Menu.Item
                                    className={`${Style.navbarItem} ${Style.navbarItemLogout}`}
                                    icon={<LogoutOutlined />}
                                    key="user:logout"
                                    onClick={user.logout}
                                >
                                    Sign Out
                                </Menu.Item>
                            </Menu.SubMenu>
                        </Fragment>
                    ) : !onAuthPage && (
                        <Menu.Item
                            className={`${Style.navbarItem} ${Style.navbarItemLogin}`}
                            key="login"
                            style={{ marginLeft: 'auto' }}
                        >
                            {user.initializing ? (
                                <Skeleton.Button
                                    active={true}
                                    className={Style.navbarItemLoginSkeleton}
                                    size="large"
                                />
                            ) : (
                                <Button
                                    className="br-5"
                                    size="large"
                                    type="primary"
                                    onClick={() => navigate('/login')}
                                >
                                    Sign In
                                </Button>
                            )}
                        </Menu.Item>
                    )}
                </Menu>
            </div>
        </div>
    );

    function renderNavbarItems(): JSX.Element {
        return (
            <Fragment>
                {navbarItems.map(navbarItem => navbarItem.items ? (
                    <Menu.SubMenu
                        className={Style.navbarItem}
                        key={navbarItem.key}
                        title={navbarItem.title}
                    >
                        {navbarItem.items.map(navbarItemItem => (
                            <Menu.Item
                                key={navbarItemItem.key}
                                onClick={() => openNavbarItem(navbarItemItem.to)}
                            >
                                {navbarItemItem.title}
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                ) : (
                    <Menu.Item
                        className={Style.navbarItem}
                        key={navbarItem.key}
                        onClick={() => openNavbarItem(navbarItem.to as string)}
                    >
                        {navbarItem.title}
                    </Menu.Item>
                ))}
            </Fragment>
        )
    }

    function openNavbarItem(to: string): void {
        navigate(to);
        if (drawerVisible) setTimeout(() => setDrawerVisible(false), 250);
    }
}

export default Header;
