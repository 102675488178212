import { createContext } from 'react';
import User from '~types/User';
import { UserContextActions } from './UserContext.types';

const UserContext = createContext<{
    initializing: boolean;
    data?: User;
} & UserContextActions>({ initializing: true });

export default UserContext;
