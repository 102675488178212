import { createContext } from 'react';
import Notification from '~types/Notification';

const NotificationsContext = createContext<{
    data?: Notification[];
    add?: (notification: Notification) => void;
    update?: (notification: Notification) => void;
    delete?: (id: string) => void;
}>({});

export default NotificationsContext;
