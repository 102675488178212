import Button from 'antd/lib/button';
import Result from 'antd/lib/result';
import Space from 'antd/lib/space';
import React, {
    Fragment,
    useContext,
    useEffect
} from 'react';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import LayoutContext from '~context/Layout';

function NotFoundPage(): JSX.Element {
    const navigate = useNavigate();
    const layout = useContext(LayoutContext);

    useEffect(() => {
        if (layout.data && layout.actions) {
            if (layout.data.header === true) layout.actions.updateHeader(false);
            if (layout.data.footer === true) layout.actions.updateFooter(false);
        }
    }, [layout.actions, layout.data]);

    return (
        <Fragment>
            <Helmet>
                <title>Not Found - Twenty4 Tan Co.</title>
            </Helmet>

            <div className="container-xl flex flex-align-center flex-justify-center my-50 px-50">
                <Result
                    className="py-0"
                    extra={(
                        <Space>
                            <Button
                                className="br-5"
                                type="default"
                                onClick={() => navigate(-1)}
                            >
                                Go Back
                            </Button>
                            <Button
                                className="br-5"
                                type="primary"
                                onClick={() => navigate('/')}
                            >
                                Home Page
                            </Button>
                        </Space>
                    )}
                    status="info"
                    subTitle="The page you were looking for does not exist"
                    title="Page not found"
                />
            </div>
        </Fragment>
    );
}

export default NotFoundPage;
