import React, {
    ReactNode
} from 'react';

function formatCardBrand(brand: string): { icon: ReactNode; name: string; } {
    let icon: ReactNode;
    let name: string;
    switch (brand) {
        case 'amex':
            icon = <i className="fab fa-cc-amex" />;
            name = 'American Express';
            break;
        case 'diners':
            icon = <i className="fab fa-cc-diners-club" />;
            name = 'Diners Club'
            break;
        case 'discover':
            icon = <i className="fab fa-cc-discover" />;
            name = 'Discover';
            break;
        case 'jcb':
            icon = <i className="fab fa-cc-jcb" />;
            name = 'JCB';
            break;
        case 'mastercard':
            icon = <i className="fab fa-cc-mastercard" />;
            name = 'Mastercard';
            break;
        case 'visa':
            icon = <i className="fab fa-cc-visa" />;
            name = 'Visa';
            break;
        case 'unionpay':
            icon = <i className="fas fa-credit-card" />;
            name = 'UnionPay';
            break;
        default:
            icon = <i className="fas fa-credit-card" />;
            name = brand;
    }

    return { icon, name };
}

export default formatCardBrand;
