import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import message from 'antd/lib/message';
import PageHeader from 'antd/lib/page-header';
import React, {
    Fragment,
    useContext,
    useEffect,
    useState
} from 'react';
import Helmet from 'react-helmet';
import config from '~config';
import UserContext from '~context/User';
import sleep from '~utilities/sleep';

type ContactFormValues = {
    emailAddress: string;

}

function ContactPage(): JSX.Element {
    const user = useContext(UserContext);

    const [contactForm] = Form.useForm<ContactFormValues>();
    const [contactFormSubmitting, setContactFormSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (user.data?.emailAddress) contactForm.setFieldsValue({ emailAddress: user.data.emailAddress });
    }, [user.data?.emailAddress]);

    return (
        <Fragment>
            <Helmet>
                <title>Contact - Twenty4 Tan Co.</title>
            </Helmet>

            <div className="container-md my-50 px-50">
                <Card
                    className="br-10 bs-grey-5"
                    title={(
                        <PageHeader
                            className="p-0"
                            title="Contact us"
                        />
                    )}
                >
                    <Form
                        colon={false}
                        form={contactForm}
                        initialValues={{
                            emailAddress: user.data?.emailAddress || '',
                            message: ''
                        }}
                        labelCol={{ span: 24 }}
                        requiredMark={false}
                        wrapperCol={{ span: 24 }}
                        onFinish={async values => {
                            if (!contactFormSubmitting) {
                                setContactFormSubmitting(true);
                                await sleep(2.5);
                                try {
                                    const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/contact`, {
                                        method: 'POST',
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify(values)
                                    });

                                    const result: HttpResult = await response.json();
                                    if (result.status === 'success') {
                                        message.success('Submission received! Our team will contact you shortly.');
                                        contactForm.resetFields();
                                    } else if (response.status === 429) message.error(typeof result.data === 'string' ? result.data : 'Failed to submit contact form');
                                    else message.error(result.message || 'Failed to submit contact form')
                                } catch (e) {
                                    console.error(e);
                                }
                                setContactFormSubmitting(false);
                            }
                        }}
                    >
                        <Form.Item
                            label="Email address"
                            name="emailAddress"
                            required={true}
                            rules={[
                                { message: 'Email address is required.', required: true },
                                { message: 'Email address is invalid.', type: 'email' }
                            ]}
                        >
                            <Input
                                autoComplete="email"
                                className="br-5"
                                placeholder="jane.doe@example.com"
                                disabled={user.data?.emailAddress !== undefined}
                                type="email"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Message"
                            name="message"
                            required={true}
                            rules={[
                                { message: 'Message is required.', required: true }
                            ]}
                        >
                            <Input.TextArea
                                autoComplete="off"
                                className="br-5"
                                placeholder="Write us a lovely message!"
                                rows={8}
                            />
                        </Form.Item>
                        <Form.Item
                            className="mb-0"
                        >
                            <Button
                                block={true}
                                className="br-5"
                                htmlType="submit"
                                loading={contactFormSubmitting}
                                type="primary"
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </Fragment>
    );
}

export default ContactPage;
