import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import Checkbox from 'antd/lib/checkbox';
import DatePicker from 'antd/lib/date-picker';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import message from 'antd/lib/message';
import PageHeader from 'antd/lib/page-header';
import Typography from 'antd/lib/typography';
import moment, { Moment } from 'moment';
import qs from 'qs';
import React, {
    Fragment,
    useContext,
    useEffect,
    useState
} from 'react';
import Helmet from 'react-helmet';
import {
    useLocation,
    useNavigate
} from 'react-router-dom';
import config from 'src/config';
import LayoutContext from '~context/Layout';
import UserContext from '~context/User';
import Style from './RegisterPage.module.less';

type RegisterFormValues = {
    firstName: string;
    lastName: string;
    emailAddress: string;
    password: string;
    confirmPassword: string;
    agreeToTerms: boolean;
    subscribeToPromotions: boolean;
};

function RegisterPage(): JSX.Element {
    const location = useLocation();
    const navigate = useNavigate();
    const layout = useContext(LayoutContext);
    const user = useContext(UserContext);

    const [registerForm] = Form.useForm<RegisterFormValues>();
    const [registerFormSubmitting, setRegisterFormSubmitting] = useState<boolean>(false);

    const [verifyingRegistration, setVerifyingRegistration] = useState<boolean>(false);

    useEffect(() => {
        if (user.data) window.location.href = '/';
    }, [user.data]);

    useEffect(() => {
        if (layout.data && layout.actions) {
            if (layout.data.header !== true) layout.actions.updateHeader(true);
            if (layout.data.footer !== true) layout.actions.updateFooter(true);
        }
    }, [layout.actions, layout.data]);

    useEffect(() => {
        const query = qs.parse(location.search, { ignoreQueryPrefix: true });
        if (query.token && typeof query.token === 'string') verifyRegistration(query.token);
    }, [location.search]);

    return (
        <Fragment>
            <Helmet>
                <title>Register - Twenty4 Tan Co.</title>
            </Helmet>

            <div className="container-xs my-50 px-50 flex flex-align-center flex-justify-center">
                <Card
                    actions={[
                        <Button
                            className="br-10"
                            disabled={registerFormSubmitting}
                            key="sign-in-button"
                            type="link"
                            onClick={() => navigate('/login')}
                        >
                            Back to login
                        </Button>
                    ]}
                    className={`${Style.registerCard} w-100 br-10 b-light-1 bs-grey-5`}
                    title={(
                        <PageHeader
                            className="p-0"
                            title="Sign up"
                        />
                    )}
                >
                    <Form
                        colon={false}
                        form={registerForm}
                        onFinish={async values => {
                            if (!registerFormSubmitting) {
                                setRegisterFormSubmitting(true);
                                const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/register`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(values)
                                });

                                if (response.status === 409) {
                                    message.error('Email address is already taken')
                                } else {
                                    const result: HttpResult = await response.json();
                                    if (result.status === 'success') {
                                        message.info('Verification email sent');
                                    } else message.error(result.message || 'Failed to register');
                                }
                                setRegisterFormSubmitting(false);
                            }
                        }}
                    >
                        <Form.Item
                            hasFeedback={true}
                            name="firstName"
                            required={true}
                            rules={[
                                { message: 'First name is required.', required: true }
                            ]}
                        >
                            <Input
                                autoComplete="given-name"
                                className="br-5"
                                placeholder="First name"
                                type="text"
                            />
                        </Form.Item>
                        <Form.Item
                            hasFeedback={true}
                            name="lastName"
                            required={true}
                            rules={[
                                { message: 'Last name is required.', required: true }
                            ]}
                        >
                            <Input
                                autoComplete="family-name"
                                className="br-5"
                                placeholder="Last name"
                                type="text"
                            />
                        </Form.Item>
                        <Form.Item
                            hasFeedback={true}
                            name="dateOfBirth"
                            rules={[
                                { message: 'Date of birth is required.', required: true },
                                { message: 'Date of birth must be a date.', type: 'date' },
                                {
                                    validator: (_, value) => {
                                        if (!moment.isMoment(value)) return Promise.reject('Not a valid date.');
                                        if (moment().diff(value as Moment, 'years') < 18) return Promise.reject('Minors are not allowed to self-register - please contact support.');
                                        return Promise.resolve();
                                    }
                                }
                            ]}
                        >
                            <DatePicker
                                className="br-5 w-100"
                                defaultPickerValue={moment().subtract(18, 'years')}
                                placeholder="Date of birth"
                            />
                        </Form.Item>
                        <Form.Item
                            hasFeedback={true}
                            name="emailAddress"
                            required={true}
                            rules={[
                                { message: 'Email address is required.', required: true },
                                { message: 'Email address is invalid.', type: 'email' }
                            ]}
                        >
                            <Input
                                autoComplete="username"
                                className="br-5"
                                placeholder="Email address"
                                type="email"
                            />
                        </Form.Item>
                        <Form.Item
                            hasFeedback={true}
                            name="password"
                            required={true}
                            rules={[
                                { message: 'Password is required.', required: true },
                                { message: 'Password must be at least 8 characters long.', min: 8 },
                                { validator: (_, value: string) => value && value.split('').some(o => o === o.toUpperCase()) ? Promise.resolve() : Promise.reject('Password must contain at least 1 uppercase letter.') },
                                { validator: (_, value: string) => value && value.split('').some(o => /^-?\d+$/.test(o)) ? Promise.resolve() : Promise.reject('Password must contain at least 1 number.') }
                            ]}
                        >
                            <Input.Password
                                autoComplete="new-password"
                                className="br-5"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item
                            dependencies={['password']}
                            hasFeedback={true}
                            name="confirmPassword"
                            required={true}
                            rules={[
                                { message: 'Passwords do not match.', validator: (_, value: string) => value === registerForm.getFieldValue('password') ? Promise.resolve() : Promise.reject() }
                            ]}
                        >
                            <Input.Password
                                autoComplete="new-password"
                                className="br-5"
                                placeholder="Confirm password"
                            />
                        </Form.Item>
                        <Form.Item
                            className="mb-0"
                            name="agreeToTerms"
                            required={true}
                            rules={[
                                { message: 'You must agree to the terms of service.', validator: (_, value: boolean) => value === true ? Promise.resolve() : Promise.reject() }
                            ]}
                            validateTrigger="onSubmit"
                            valuePropName="checked"
                        >
                            <Checkbox>
                                I agree to the <Typography.Link href="/terms" rel="noreferrer" target="_blank">terms of service</Typography.Link>
                            </Checkbox>
                        </Form.Item>
                        <Form.Item
                            name="subscribeToPromotions"
                            required={true}
                            valuePropName="checked"
                        >
                            <Checkbox>
                                Subscribe to updates and offers from Twenty4 Tan Co.
                            </Checkbox>
                        </Form.Item>
                        <Form.Item
                            className="mb-0"
                        >
                            <Button
                                block={true}
                                className="br-5"
                                htmlType="submit"
                                loading={registerFormSubmitting}
                                type="primary"
                            >
                                Register
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </Fragment>
    );

    async function verifyRegistration(token: string) {
        if (!verifyingRegistration) {
            setVerifyingRegistration(true);
            try {
                const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/register/verify`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        token
                    })
                });

                const result: HttpResult = await response.json();
                if (result.status === 'success') {
                    message.success('Account registered, please log in.');
                    navigate('/login');
                }
            } catch (e) {
                console.error(e);
            }
            setVerifyingRegistration(false);
        }
    }
}

export default RegisterPage;
