import {
    CardElement,
    Elements
} from '@stripe/react-stripe-js';
import {
    PaymentMethod,
    StripeCardElement
} from '@stripe/stripe-js';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import React, {
    useContext,
    useState
} from 'react';
import config from '~config';
import StripeContext from '~context/Stripe';
import sleep from '~utilities/sleep';
import { StripePaymentMethodFormProps } from './StripePaymentMethodForm.types';

function StripePaymentMethodForm(props: StripePaymentMethodFormProps): JSX.Element {
    const {
        onCancel,
        onError,
        onSuccess
    } = props;

    const stripe = useContext(StripeContext);

    const [cardElement, setCardElement] = useState<StripeCardElement>();

    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);

    return (
        <Elements
            stripe={stripe}
        >
            <Form
                colon={false}
                requiredMark={false}
                onFinish={async () => {
                    if (stripe && cardElement && !formSubmitting) {
                        setFormSubmitting(true);
                        await sleep(1);
                        try {
                            const paymentMethodResult = await stripe.createPaymentMethod({
                                type: 'card',
                                card: cardElement
                            });
                            if (paymentMethodResult.error) throw new Error(JSON.stringify(paymentMethodResult.error));

                            const response = await fetch(`${config.TWENTY4TANCO_API_BASE_URL}/stripe/payment-methods`, {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    id: paymentMethodResult.paymentMethod.id
                                }),
                                credentials: 'include'
                            });

                            const result: HttpResult<PaymentMethod> = await response.json();
                            if (result.status === 'success' && result.data) {
                                cardElement.clear();
                                if (onSuccess) onSuccess(result.data);
                            } else {
                                if (onError) onError(result.message || 'Failed to add payment method.');
                            }
                        } catch (e) {
                            console.error(e);
                        }
                        setFormSubmitting(false);
                    }
                }}
                onReset={() => {
                    if (cardElement) cardElement.clear();
                    if (onCancel) onCancel();
                }}
            >
                <Form.Item
                    name="card"
                    rules={[
                        {
                            validator: (_, value) => {
                                if (typeof value === 'object' && value.error) return Promise.reject(value.error);
                                return Promise.resolve();
                            }
                        }
                    ]}
                >
                    <CardElement
                        onReady={element => setCardElement(element)}
                    />
                </Form.Item>
                <Form.Item
                    className="mb-0"
                >
                    <Typography.Paragraph type="secondary">
                        Your card will not be charged unless you set it as the default payment method for your subscription plan.
                    </Typography.Paragraph>
                    <div className="text-right">
                        <Space align="center">
                            <Button
                                className="br-5"
                                disabled={formSubmitting}
                                htmlType="reset"
                            >
                                Cancel
                            </Button>
                            <Button
                                className="br-5"
                                htmlType="submit"
                                loading={formSubmitting}
                                type="primary"
                            >
                                Add
                            </Button>
                        </Space>
                    </div>
                </Form.Item>
            </Form>
        </Elements>
    );
}

export default StripePaymentMethodForm;
