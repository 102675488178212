import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import PageHeader from 'antd/lib/page-header';
import moment from 'moment';
import React, {
    Fragment,
    useContext
} from 'react';
import Helmet from 'react-helmet';
import NotificationList from '~components/NotificationList';
import NotificationsContext from '~context/Notifications';

function NotificationsPage(): JSX.Element {
    const notifications = useContext(NotificationsContext);

    const sortedNotifications = notifications.data
        ? notifications.data.sort((a, b) =>
            moment(b.metadata.createdAt).valueOf() -
            moment(a.metadata.createdAt).valueOf())
        : undefined;

    const updateNotification = notifications.update;
    const deleteNotification = notifications.delete;

    return (
        <Fragment>
            <Helmet>
                <title>Notifications - Twenty4 Tan Co.</title>
            </Helmet>

            <div className="container-lg my-50 px-50">
                <Card
                    className="br-10 bs-grey-5"
                    title={(
                        <PageHeader
                            className="p-0"
                            title="Notifications"
                        />
                    )}
                >
                    <NotificationList
                        loading={!notifications.data}
                        notifications={sortedNotifications?.filter(n => !n.metadata.deletedAt)}
                        emptyText="No notifications"
                        onUpdate={updateNotification ? notification => updateNotification(notification) : undefined}
                        onDelete={deleteNotification ? id => deleteNotification(id) : undefined}
                    />
                </Card>
            </div>
        </Fragment>
    )
}

export default NotificationsPage;
