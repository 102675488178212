import React, {
    Fragment,
    useContext
} from 'react';
import {
    Navigate,
    RouteProps
} from 'react-router-dom';
import UserContext from '~context/User';

function PrivateRoute({ element, path }: RouteProps): JSX.Element {
    const user = useContext(UserContext);

    const redirectUrl = path ? path : undefined;

    return user.initializing
        ? <Fragment />
        : user.data
            ? <Fragment>{element}</Fragment>
            : <Navigate
                to={`/login${redirectUrl ? `?redirect=${redirectUrl}` : ''}`}
            />;
}

export default PrivateRoute;
