import { init } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from 'react';
import { render } from 'react-dom';
import App from './App';
import config from '~config';
import './index.less';

if (config.SENTRY_DSN) init({
    dsn: config.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: config.SENTRY_TRACES_SAMPLE_RATE || 1.0
});

render(<App />, document.getElementById('root'));
