const config = {
    // webpack.config.js
    ENVIRONMENT: process.env['ENVIRONMENT'] as 'development' | 'production',

    // .env
    TWENTY4TANCO_API_BASE_URL: process.env['TWENTY4TANCO_API_BASE_URL'] as string,
    SENTRY_DSN: process.env['SENTRY_DSN'],
    SENTRY_TRACES_SAMPLE_RATE: process.env['SENTRY_TRACES_SAMPLE_RATE'] ? parseFloat(process.env['SENTRY_TRACES_SAMPLE_RATE']) : undefined,
    STRIPE_PUBLISHABLE_KEY: process.env['STRIPE_PUBLISHABLE_KEY'] as string
};

export default config;
