import { createContext } from 'react';
import {
    LayoutContextActions,
    LayoutContextData
} from './LayoutContext.types';

const LayoutContext = createContext<{
    data?: LayoutContextData;
    actions?: LayoutContextActions;
}>({});

export default LayoutContext;
